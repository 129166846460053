import React, {useState, useContext, useRef, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { toast } from "react-toastify";
import clsx from  'clsx';

import {
    Drawer,
    makeStyles,
    FormControl,
    InputLabel,
    TextField,
    MenuItem,
    SvgIcon,
    Select,
    ButtonBase,
    Fade,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";

import api, { request } from '../../services/api';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles((theme) => ({
    bloqueioModal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
      borderRadius: "8px",
      background: "#ededed",
      overflow: "hidden",
    },
    bloqueioModalTop: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      padding: "20px",
      color: "#fff",
      background: "#2f3337",
      fontSize: "22px",
      minWidth: "580px",
      textAlign: "center",
    },
    bloqueioMenu: {
      display: "table",
      margin: "18px auto",
      background: "#2f3337",
      color: "#fff",
      minWidth: "380px",
      padding: "8px 15px",
      borderRadius: "8px",
      transition: "all .3s",
      cursor: "pointer",
      userSelect: "none",
    },
    bloqueioModalContent: {
        display: "grid",
        justifyItems: "center",
        padding: "25px 15px",
    },
    bloqueioOpt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "none",
    },
    bloqueioSeparador: {
      width: "380px",
      height: "1px",
      background: "#cbcbcb",
      margin: "0 auto",
    },
    backdrop: {
      zIndex: 9999,
    },
    formControl: {
      minWidth: "500px",
      display: "table",
      margin: "0 auto",
      textAlign: "center",
    },
    formSelect: {
      minWidth: "500px",
    },
    bloqueioVars: {
      display: "none",
      gap: "15px",
      justifyContent: "center",
      gridTemplateColumns: "auto auto",
    },
    bloqueioInput: {
      minWidth: "240px",
      marginTop: "15px",
    },
    bloqueioBtn: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      justifyContent: "center",
      padding: "8px 14px",
      marginTop: "15px",
    },
}));
const TelaBloqueio = ({ open, onClose }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [bloqueioPt1, setBloqueioPt1] = useState(true);
    const [bloqueioPt2, setBloqueioPt2] = useState(false);
    const { user } = useContext(AuthContext);
    var minimoMinutos = 0;
    var textVar = "";
    var MttIdVar = "";
    var MtTipoVar = "";

    if(!open && !bloqueioPt1) { // Prepare re-open
      setBloqueioPt1(true);
      setBloqueioPt2(false);
    }

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = today.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    };
          
    const isMounted = useRef(true);
    const [typeMessage, setTypeMessage] = useState([]);

    const atualizarValidacao = async () => {
      try {
        const { data } = await api.put('/BatchMessages/validateMessage', {
          userId: user.id,
        })
        setTypeMessage(data);
      } catch (err) {
        toast.error(`Erro na conexão com a API (0x1349)`);
      }
    };
      
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
          const fecthTypeMessage = async () => {
            await atualizarValidacao();
          };
          fecthTypeMessage();
        }, 500);
        return () => {  isMounted.current = false; clearTimeout(delayDebounceFn); }
    }, []);
    

    const MenuBloqueio = (props) => {
      const classes = useStyles();

      function hoverIn(evt) {
          let temp = false;
          if (typeMessage.length > 0) {
              typeMessage.map(e => {
                  if (e.MttId == props.btnId && e.MtTipo == props.tipo) {
                    evt.target.style.background = "#f44336";
                    temp = true;
                  } 
              })
          } 

          if (!temp) {
            evt.target.style.background = "#55595e";
          }
      }

      function hoverOut(evt) {
          evt.target.style.background = "#2f3337";
      }

      async function realizarEventos(evt, tipo, text, icon) {
        setLoading(true);
         if (typeMessage.length > 0) {
            let temp = false
            typeMessage.map(e => {
                if (e.MttId == props.btnId && e.MtTipo == props.tipo) {
                  toast.error(`Opção INVALIDA! Você atingiu o maximo diario desse tipo de mensagem!`);
                  temp = true;
                } 
            })

            if (temp) {
              setLoading(false);
              return;
            }
        } 


        setLoading(true);
        MttIdVar = evt;
        MtTipoVar = tipo;
        var result = await request.get(`${process.env.REACT_APP_API_LAMPZAP}/buscarTemplates?InNome=${process.env.REACT_APP_LOJA}&MttId=${evt}&MtTipo=${tipo}`).catch(e => {
          toast.error(`Erro na conexão com a API (Erro ${e.response.status??'0x101'})`);
          onClose();
        });
        setLoading(false);
        
        if(result && result.status) {
          var dados = result.data;
          if(dados.templates.length > 0) {
            setBloqueioPt1(false); // Desativa modal pt1
            var list = [];

            dados.templates.forEach((item, i) => {
              list.push(
                <MenuItem key={i} value={i}>{item.msgTemplate}</MenuItem>
              );
            });

            const obterVars = (event) => {
              var i = event.target.value;
              textVar = dados.templates[i].msgTemplate;
              minimoMinutos = dados.templates[i].minimoMinutos;
              if(dados && dados.templates[i] && dados.templates[i].variaveis.length > 0) {
                var vars = [];
                dados.templates[i].variaveis.forEach(item => {
                  vars.push(
                    <TextField
                      id={item.variavel}
                      data-obrigatorio={item.MtvObrigatorio}
                      data-tipo={item.MtvTipo.toLowerCase()}
                      data-variavel={item.variavel}
                      label={item.MtvNomeCampo}
                      type={item.MtvTipo.toLowerCase()}
                      variant="outlined"
                      className={classes.bloqueioInput}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: item.MtvTipo.toLowerCase() == "date" ? getCurrentDate() : undefined,
                      }}
                    />
                  );
                });
                var el = document.querySelector('#bloqueioVars');
                ReactDOM.render(
                  vars,
                  el
                );
                el.style.display = 'grid';
              } else {
                document.querySelector('#bloqueioVars').innerHTML = "";
                console.log(`Não há variaveis (0x2)`);
              }
            };
            
            const enviarRequisicao = async () => {
              var el = document.querySelectorAll('[data-variavel]'), str = textVar, err = false;
              el.forEach(item => {
                var id = item.attributes['data-variavel'].value,
                tipo = item.attributes['data-tipo'].value,
                vlr = document.querySelector(`[data-variavel="${id}"] input`).value;

                for(let item of el) {
                  var id = item.attributes['data-variavel'].value,
                  tipo = item.attributes['data-tipo'].value,
                  vlr = document.querySelector(`[data-variavel="${id}"] input`).value;
  
                  if(item.attributes['data-obrigatorio'].value == "true" && vlr == "") {
                    err = `Você não informou todas as váriaveis!`;
                    break;
                  }
  
                  str = str.replaceAll(`[${id}]`, tipo=="date"? new Date(vlr).toLocaleDateString('pt-BR').split('/').slice(0, -1).join('/') : vlr);
                }
              });

              if(!err) {
                setLoading(true);
                var res = await api.post('/BatchMessages/', {
                  MttId: MttIdVar,
                  MtTipo: MtTipoVar,
                  userId: user.id,
                  minimunMinutes: minimoMinutos,
                  Message: `${user.name}: ${str}`,
                }).catch(err => {
                  toast.error(`Erro na conexão com a API (0x03)`);
                  setLoading(false);
                });
                setLoading(false);
                if(res && res.status == 200) {
                  if(tipo != "0")
                    localStorage.setItem("userLocked", JSON.stringify({
                      locked: true,
                      MttId: MttIdVar,
                      lockDateIn: +new Date(),
                    }));
                    await atualizarValidacao();

                  toast.success(`Mensagem agendada!`);
                  onClose();
                } else {
                  toast.error(`Erro na conexão com a API (0x02)`);
                }
              } else toast.error(err);
            };

            var element = (
              <>
                <div data-id="nome" className={classes.bloqueioModalTop}><SvgIcon component={props.icon}/> {props.text}</div>
                <div data-id="content" className={classes.bloqueioModalContent}>
                  <FormControl
                    className={classes.formControl}
                    variant="outlined"
                  >
                  <InputLabel id="bloqueio-label">Escolha a mensagem a ser enviada</InputLabel>
                    <Select
                      className={classes.formSelect}
                      labelId="bloqueio-label"
                      label="Escolha a mensagem a ser enviada"
                      id="bloqueio-form"
                      onChange={obterVars}
                    >
                      {list}
                    </Select>
                  </FormControl>
                  <div className={classes.bloqueioVars} id="bloqueioVars"></div>
                  <ButtonBase
                    className={clsx(classes.bloqueioBtn, "MuiButton-root")}
                    onClick={enviarRequisicao}
                    style={{
                      background: '#1976d2',
                      color: '#fff',
                    }}
                  >
                    <SendIcon /> Enviar mensagem em lote
                  </ButtonBase>
                </div>
              </>
            );

            ReactDOM.render(
              element,
              document.querySelector('#bloqueioPt2')
            );
            setBloqueioPt2(true); // Ativa modal pt2
          } else {
            toast.error(`Configuração não encontradas, contate o suporte!`);
          }
        } else if(result && result.status === false) {
          toast.error(`Erro na conexão com a API (${result.msg??'N/A'})`);
          onClose();
        } else {
          toast.error(`Erro na conexão com a API (Erro 0x100)`);
          onClose();
        }
      }
      return (
          <div className={classes.bloqueioMenu} onMouseEnter={hoverIn} onMouseLeave={hoverOut} onClick={() => realizarEventos(props.btnId, props.tipo, props.text, props.icon)}>
              <div className={classes.bloqueioOpt}>
              <div style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
              }}>
                  <SvgIcon component={props.icon}/>
              </div>
              <div style={{marginLeft: "5px", fontSize: "18px"}}>{props.text}</div>
              </div>
          </div>
      );
    };

    return (
      <Drawer
        open={open}
        onClose={onClose}
      >
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress style={{color: "#fff"}} />
        </Backdrop>
        <Fade
          in={open && bloqueioPt1? true : false}
          style={{
            transitionDelay: '200ms',
          }}
        >
          <div className={classes.bloqueioModal}>
            <div className={classes.bloqueioModalTop}>Escolha o tipo de mensagem:</div>
            <div className={classes.bloqueioModalContent}>
              <MenuBloqueio
                text="Encerramento de expediente"
                icon={AccessAlarmIcon}
                btnId="1"
                tipo="1"
              />
              <div className={classes.bloqueioSeparador}></div>
              <MenuBloqueio
                text="Inicio de expediente"
                icon={HeadsetMicIcon}
                btnId="1"
                tipo="0"
              />
              <div className={classes.bloqueioSeparador}></div>
              <MenuBloqueio
                text="Saída para almoço"
                icon={RestaurantIcon}
                btnId="2"
                tipo="1"
              />
              <div className={classes.bloqueioSeparador}></div>
              <MenuBloqueio
                text="Ausência temporária"
                icon={TimelapseIcon}
                btnId="3"
                tipo="1"
              />
            </div>
          </div>
        </Fade>
        <Fade
          in={bloqueioPt2}
          style={{
            transitionDelay: '800ms',
          }}
        >
          <div id="bloqueioPt2" className={classes.bloqueioModal}></div>
        </Fade>
      </Drawer>
    );
}

export default TelaBloqueio;