import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ListItemText, Checkbox } from "@material-ui/core";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const TicketsProductSelect = ({
	selectedProductIds = [],
	onChange,
}) => {
	const handleChange = e => {
		onChange(e.target.value);
	};

    const [products, setProducts] = useState([]);

   useEffect(() => {(async () => {
            try {
                const { data } = await api.get("/products");
                setProducts(data.products);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []); 

	return (
		<div style={{ width: 107, marginTop: -4, marginLeft: 25 }}>
			<FormControl fullWidth margin="dense">
				<Select
					displayEmpty
					variant="outlined"
					value={selectedProductIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={() => {
						if (selectedProductIds.length == 0) {
							return i18n.t("ticketsProductSelect.placeholder")
						} else {
							const product = products.filter(function(product) {
								return (product.id == selectedProductIds)
							})[0];

							return (<span style={{ color: product?.color }}>{ product?.name }</span>) ?? "Sem filtro";
						}
					}}
				>
					{products?.length > 0 &&
						products.map(product => (
							selectedProductIds.includes(product.id) == false &&
							<MenuItem dense key={product.id} value={product.id}>
								 <Checkbox
									style={{
										color: product.color,
										backgroundColor: product.color,
										width: "3px",
										height: "3px",
										borderRadius: "40%",
										marginRight: "0.5em",
									}}
									size="small"
									color="primary"
								/>

								<ListItemText primary={product.name}/>
							</MenuItem>
						))}

						{
                            selectedProductIds != 0 && 

                            <MenuItem dense value={0}>
                                <Checkbox
									style={{
										color: "#000000",
										backgroundColor: "#000000",
										width: "3px",
										height: "3px",
										borderRadius: "40%",
										marginRight: "0.5em",
									}}
									size="small"
									color="primary"
								/>
								<ListItemText primary={"Sem Filtro"}/>
							</MenuItem>
                        }
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsProductSelect;
