import React, { useState, useEffect } from "react";

import {
    Dialog,
    DialogContent,
    DialogTitle,
    Button,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { AuthContext } from "../../context/Auth/AuthContext";
import { useContext } from "react";
import ButtonWithSpinner from "../ButtonWithSpinner";

const WhatsAppListModal = ({ open, onClose, whatsappId, onSelectWhatsapp, selectedContactId }) => {
    const { user } = useContext(AuthContext);
    const [whatsapps, setWhatsapps] = useState([]);

    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get(`/whatsappList/${user.id}`);
                setWhatsapps(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, [whatsappId]);

    const handleClose = () => {
        onClose();
    };

    const handleConfirm = () => {
        onSelectWhatsapp(selectedWhatsapp);
        onClose();
    };

    return (
        <Dialog open={open} maxWidth="lg" scroll="paper">
            <DialogTitle id="form-dialog-title">
                Atribuir linha
            </DialogTitle>
                <DialogContent dividers>
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel>Linha</InputLabel>
                    <Select
                        labelWidth={60}
                        value={selectedWhatsapp}
                        name="whatsappId"
                        onChange={(e) => { setSelectedWhatsapp(e.target.value);  console.log(e.target.value)}}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        { whatsapps.map(whatsapp => (
                            <MenuItem key={whatsapp.id} value={whatsapp.id}>
                                {whatsapp.name}
                            </MenuItem>
                        )) }
                    </Select>
                </FormControl>
                </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="outlined"
                        >
                            Cancelar
                        </Button>
                        <ButtonWithSpinner
                            type="button"
                            onClick={handleConfirm}
                            variant="contained"
                            color="primary"
                        >
                            Atribuir
                        </ButtonWithSpinner>
                </DialogActions>
                    
        </Dialog>
    );
};

export default React.memo(WhatsAppListModal);
