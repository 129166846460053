import React, { useState, useEffect, useRef, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

  textFieldDuplo: {
    marginRight: theme.spacing(1),
    width: "50%",
  },
  btnWrapper: {
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    position: "relative",

  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
  dFlex: {
    display:"flex",
    flexWrap:"wrap",
    justifyContent: "center",
  },
  p1: {
    padding: "10px 20px",
    display:"block",
  },
  ticketOrder: {
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"space-between"
  },
}));


const searchSchema = Yup.object().shape({
  CPF: Yup.string()
      .min(11, 'Cpf não está no tamanho certo...')
      .max(14, 'Cpf não está no tamanho certo...!'),
  NOME: Yup.string()
      .min(4, "O nome está muito curto")
      .max(100, "O nome está muito grande"),
  TELEFONE: Yup.string()
      .min(8, "O número de telefone deve ser válido! Ex: 55 11 90000-0000")
      .max(19, "O número de telefone deve ser válido! Ex: 55 11 90000-0000"),
  TICKET: Yup.string()
    .min(1, "O número do ticket deve ser válido")
    .max(12, "O número do ticket deve ser válido"),
  TEMPO_S_INTERACAO: Yup.string()
    .min(1, 'O valor precisa ser maior ou igual a 1')
    .max(1000, 'O valor precisa ser menor ou igual a 1000')
});

const reducerUser = (state, action) => {
  const returnData = action.payload;
  const newReturnData = [];
  returnData.forEach((user) => {
    const userIndex = state.findIndex((u) => u.id === user.id);
    if (userIndex !== -1) {
      state[userIndex] = user;
    } else {
      newReturnData.push(user);
    }
  });
  

  return [...state, ...newReturnData];
}

const reducerTag = (state, action) => {
    const returnData = action.payload;
    const newReturnData = [];
    returnData.forEach((tag) => {
      const tagIndex = state.findIndex((t) => t.id === tag.id);
      if (tagIndex !== -1) {
        state[tagIndex] = tag;
      } else {
        newReturnData.push(tag);
      }
    });

    return [...state, ...newReturnData]
}

const reducerProduct = (state, action) => {
  const returnData = action.payload;
  const newReturnData = [];
  returnData.forEach((product) => {
    const productIndex = state.findIndex((t) => t.id === product.id);
    if (productIndex !== -1) {
      state[productIndex] = product;
    } else {
      newReturnData.push(product);
    }
  });

  return [...state, ...newReturnData]
}


const SearchModal = ({
  open,
  onClose,
  tipoFiltro,
  textoFiltro,
  initialValues,
  handleSearchSubmit,
  onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);
  const initialState = {
    tipoFiltro: tipoFiltro
  };

  const [returnDataTags, dispatchTag] = useReducer(reducerTag, []);
  const [returnDataProducts, dispatchProduct] = useReducer(reducerProduct, []);

  const [returnDataUsers, dispatchUser] = useReducer(reducerUser, []);

  useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        const fetchUsers = async () => {
          try {
            const { data } = await api.get("/users/");
            dispatchUser({ type: "LOAD_USERS", payload: data.users });
          } catch (err) {
            toastError(err);
          }
        };
        fetchUsers();
      }, 500);
      return () => {   isMounted.current = false; clearTimeout(delayDebounceFn); }
  }, [])

  useEffect(() => {

      const delayDebounceFn = setTimeout(() => {
        const fetchTags = async () => {
          try {
            const { data } = await api.get("/tags");
            dispatchTag({ type: "FILTRAR_ETIQUETAS", payload: data.tags });
          } catch (err) {
            toastError(err);
          }
        };
        fetchTags();
      }, 500);
      return () => {   isMounted.current = false; clearTimeout(delayDebounceFn); }
  }, [])
  
  useEffect(() => {

    const delayDebounceFn = setTimeout(() => {
      const fetchProducts = async () => {
        try {
          const { data } = await api.get("/products");
          dispatchProduct({ type: "FILTRAR_PRODUTOS", payload: data.products });
        } catch (err) {
          toastError(err);
        }
      };
      fetchProducts();
    }, 500);
    return () => {   isMounted.current = false; clearTimeout(delayDebounceFn); }
  }, [])

  const handleClose = () => {
    setTempoDe("");
    setTempoAte("");
    onClose();
  };
  const handleSave = () => {
    onSave();
  };

  const options = [
    { value: "ABERTO", label: "ABERTO" },
    { value: "AGUARDANDO", label: "AGUARDANDO" },
    { value: "ENCERRADO", label: "ENCERRADO" },
  ];
  
  const [tempoDe, setTempoDe] = useState("");
  const [tempoAte, setTempoAte] = useState("");
  const [selecaoTags, setTag] = useState([]);
  const [selecaoProducts, setProduct] = useState([]);

  const handleTempoDeChange = (event) => {
    setTempoDe(event.target.value);
  };
  
  const handleTempoAteChange = (event) => {
    setTempoAte(event.target.value);
  };
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        scroll="paper"
      >
        <DialogTitle id="form-dialog-title">
            FILTRO - {textoFiltro}
        </DialogTitle>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={searchSchema}
          tipoFiltro={tipoFiltro}
          
          onSubmit={(values, actions) => {
            setTimeout(() => {
              let selectedValue = null;
              if(tipoFiltro === "VENDEDOR"){
                const select = returnDataUsers.find((u) => u.id === parseInt(values['VENDEDOR']));
                selectedValue = select ? select.name : "";
              }else if (tipoFiltro === "FILTRAR_ETIQUETAS"){
                values.FILTRAR_ETIQUETAS = selecaoTags;
                const select = returnDataTags.filter((u) => selecaoTags.includes(u.id.toString()));
                selectedValue = select.map(item => item.name).join(', ');

              }else if (tipoFiltro === "FILTRAR_PRODUTOS"){
              values.FILTRAR_PRODUTOS = selecaoProducts;
              const select = returnDataProducts.filter((u) => selecaoProducts.includes(u.id.toString()));
              selectedValue = select.map(item => item.name).join(', ');

              }else if (tipoFiltro === "TEMPO_S_INTERACAO"){
                  if(!(tempoDe > 0 && tempoAte > 0) && (tempoDe >= tempoAte)){
                    toast.error(`Os campos informados estão inválidos!`);
                    actions.setSubmitting(false);
                    return;
                  }
                 values.TEMPO_S_INTERACAO = [tempoDe, tempoAte];
              }else if (tipoFiltro === "DATA_ULTIMA_MENSAGEM"){
                if(!(tempoDe.length > 0 && tempoAte.length > 0)){
                  toast.error(`Os campos informados estão inválidos!`);
                  actions.setSubmitting(false);
                  return;
                }
                values.DATA_ULTIMA_MENSAGEM = [tempoDe, tempoAte];
              }else{
                selectedValue = values[tipoFiltro];
              }
              setTag([])
              setProduct([])
              setTempoDe("");
              setTempoAte("");
              handleSearchSubmit(values, selectedValue );
              handleClose();
              actions.setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent dividers>
                <div className={classes.textQuickAnswerContainer}>
                  {
                    tipoFiltro === "VENDEDOR"  ? (
                    	<Field
                      margin="dense"
                      variant="outlined"
                      native
                      fullWidth
                      autoFocus
                      as={Select}

                      name={tipoFiltro}                 
                    >
                     
                            <option></option>
                            {returnDataUsers.map((user) => (
                              <option key={user.id} value={user.id}>{user.name}</option>
                            ))}
                         
                    </Field>
                    ) : tipoFiltro === "STATUS_TICKET"  ? (
                    <div className={classes.ticketOrder}>
                      {options.map((option) => (
                        <div key={option.value}>
                          <label>
                            <Field
                              type="checkbox"
                              name={tipoFiltro}
                              value={option.value}                           
                            />
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>

                    ) :   tipoFiltro === "TEMPO_S_INTERACAO" ? (                      
                        <div className={classes.multFieldLine}>
                          <TextField
                            margin="dense"
                            variant="outlined"
                            label="De (Minutos)"
                            name="TEMPO_S_INTERACAO_de"
                            value={tempoDe}
                            onChange={handleTempoDeChange}
                            className={classes.textFieldDuplo}
                            
                          />
                          <TextField
                            margin="dense"
                            variant="outlined"
                            label="Até (Minutos)"
                            name="TEMPO_S_INTERACAO_ate"
                            value={tempoAte}
                            onChange={handleTempoAteChange}
                            className={classes.textFieldDuplo}
                            
                          />
                        </div>
                    ) : tipoFiltro === "DATA_ULTIMA_MENSAGEM" ? (                      
                              <div className={classes.multFieldLine}>
                                <TextField
                                  margin="dense"
                                  variant="outlined"
                                  name="DATA_ULTIMA_MENSAGEM_de"
                                  value={tempoDe}
                                  type="datetime-local"
                                  onChange={handleTempoDeChange}
                                  className={classes.textFieldDuplo}
                                  
                                />
                                <TextField
                                  margin="dense"
                                  variant="outlined"
                                  name="DATA_ULTIMA_MENSAGEM_ate"
                                  value={tempoAte}
                                  type="datetime-local"
                                  onChange={handleTempoAteChange}
                                  className={classes.textFieldDuplo}
                                  
                                />
                              </div>
                          ) : tipoFiltro === "FILTRAR_ETIQUETAS"  ? (
                            <div className={classes.dFlex}>
                              {returnDataTags.map((tag) => (
                                <div key={tag.id}>
                                  <label className={classes.p1}>
                                  <Field
                                    type="checkbox"
                                    value={tag.id}
                                    checked={values.etiquetas && values.etiquetas[tag.id]}
                                    data-name={tag.name}
                                    onChange={(e) => {
                                      const isChecked = e.target.checked;

                                      if (!isChecked) {
                                        setTag(selecaoTags.filter(tag => tag != e.target.value))
                                      } else {
                                        setTag([...selecaoTags, e.target.value])
                                      }
                                    }}
                                  />
                                    {tag.name}
                                  </label>
                                </div>
                              ))}
                            </div>
                            ) : tipoFiltro === "FILTRAR_PRODUTOS" ? (
                              <div className={classes.dFlex}>
                                {returnDataProducts.map((product) => (
                                  <div key={product.id}>
                                    <label className={classes.p1}>
                                    <Field
                                      type="checkbox"
                                      value={product.id}
                                      checked={values.produtos && values.produtos[product.id]}
                                      data-name={product.name}
                                      onChange={(e) => {
                                        const isChecked = e.target.checked;
  
                                        if (!isChecked) {
                                          setProduct(selecaoProducts.filter(product => product != e.target.value))
                                        } else {
                                          setProduct([...selecaoProducts, e.target.value])
                                        }
                                      }}
                                    />
                                      {product.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                              ) : (
                    <Field
                      as={TextField}
                      label={textoFiltro}
                      name={tipoFiltro}
                      autoFocus
                      error={touched[tipoFiltro] && Boolean(errors[tipoFiltro])}
                      helperText={touched[tipoFiltro] && errors[tipoFiltro]}
                      variant="outlined"
                      margin="dense"
                      className={classes.textField}
                      fullWidth
                    />)
                  }
                  
                </div>               
              </DialogContent>
              <div className={classes.btnWrapper}>
              <DialogActions>
                <Button
                  onClick={handleClose}
                  color="secondary"
                  disabled={isSubmitting}
                  variant="outlined"
                >
                  Voltar
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  variant="contained"
                >
                  Filtrar
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
              </div>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default SearchModal;
