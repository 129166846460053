import React, {useState, useContext} from 'react';
import { toast } from 'react-toastify';
import ReactDOM from 'react-dom';
import clsx from  'clsx';

import {
    Fade,
    Drawer,
    Select,
    ButtonBase,
    SvgIcon,
    MenuItem,
    TextField,
    makeStyles,
    InputLabel,
    FormControl,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";

import RestaurantIcon from '@material-ui/icons/Restaurant';
import ComputerIcon from '@material-ui/icons/Computer';
import HelpIcon from '@material-ui/icons/Help';
import SendIcon from '@material-ui/icons/Send';

import api, { request } from '../../services/api';

const useStyles = makeStyles((theme) => ({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    borderRadius: "8px",
    background: "#ededed",
    overflow: "hidden",
  },
  modalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "20px",
    color: "#fff",
    background: "#2f3337",
    fontSize: "22px",
    minWidth: "580px",
    textAlign: "center",
  },
  menuTrava: {
    display: "table",
    background: "#2f3337",
    color: "#fff",
    minWidth: "380px",
    padding: "8px 15px",
    borderRadius: "8px",
    transition: "all .3s",
    cursor: "pointer",
    userSelect: "none",
    marginTop: "5px",
    marginBottom: "10px",
  },
  menuTravaOpt: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pointerEvents: "none",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
  },
  contentTrava: {
    display: "grid",
    justifyItems: "center",
    padding: "25px 0",
  },
  formControl: {
    minWidth: "500px",
    display: "table",
    margin: "0 auto",
    textAlign: "center",
  },
  formSelect: {
    minWidth: "500px",
  },
  bloqueioVars: {
    display: "none",
    gap: "15px",
    justifyContent: "center",
    gridTemplateColumns: "auto auto",
  },
  bloqueioInput: {
    minWidth: "240px",
    marginTop: "15px",
  },
  bloqueioBtn: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 14px",
    marginTop: "15px",
  },
  travaTitle: {
    fontSize: "1.25rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    color: "#8d8d8d",
  },
  travaCount: {
    fontSize: "5rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    color: "#8d8d8d",
  }
}));

const TelaTrava = ({open, canCancel, MttId, loader, setTelaTrava, timeLeft}) => {
    const classes = useStyles();
    const [modal, openModal] = useState(false);
    const { user } = useContext(AuthContext);
    var dados = [];
    var textVar = "";
    var MttIdVar = "";
    var MtTipoVar = "";
    var minimoMinutos = 0;

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      if (month < 10) {
        month = `0${month}`;
      }
      let day = today.getDate();
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    };

    const fmtMSS = (s) => {return(s-(s%=60))/60+(9<s?':':':0')+s};

    const obterVars = async () => {
      const enviarRequisicao = async () => {
        var selector = document.querySelector('#bloqueio-form'), el = document.querySelectorAll('[data-variavel]'), str = textVar, err = false;
        el.forEach(item => {
          var id = item.attributes['data-variavel'].value,
          tipo = item.attributes['data-tipo'].value,
          vlr = document.querySelector(`[data-variavel="${id}"] input`).value;

          for(let item of el) {
            var id = item.attributes['data-variavel'].value,
            tipo = item.attributes['data-tipo'].value,
            vlr = document.querySelector(`[data-variavel="${id}"] input`).value;

            if(item.attributes['data-obrigatorio'].value == "true" && vlr == "") {
              err = `Você não informou todas as váriaveis obrigatorias!`;
              break;
            }

            str = str.replaceAll(`[${id}]`, tipo=="date"? new Date(vlr).toLocaleDateString('pt-BR').split('/').slice(0, -1).join('/') : vlr);
          }
        });

        if(err) {
          return toast.error(err);
        } else if(selector.innerText.length <= 1) {
          return toast.error("Você não escolheu nenhuma das alternativas de resposta automática.");
        }

        loader(true);
        var res = await api.post('/BatchMessages/', {
          MttId: MttId,
          MtTipo: 0,
          userId: user.id,
          minimunMinutes: minimoMinutos,
          Message: `${user.name}: ${str}`,
        }).catch(err => {
          toast.error(`Erro na conexão com a API (0x03)`);
          loader(false);
        });
        loader(false);
        if(res && res.status == 200) {
          localStorage.setItem("userLocked", JSON.stringify({
            locked: false,
            MttId: MttId,
          }));

          toast.success(`Mensagem agendada!`);
          loader(true);
        } else {
          toast.error(`Erro na conexão com a API (0x02)`);
        }
      };

      const processarVars = (event) => {
        var i = event.target.value;
        textVar = dados.templates[i].msgTemplate;
        minimoMinutos = dados.templates[i].minimoMinutos;
        if(dados && dados.templates[i] && dados.templates[i].variaveis.length > 0) {
          var vars = [];
          dados.templates[i].variaveis.forEach(item => {
            vars.push(
              <TextField
                id={item.variavel}
                data-obrigatorio={item.MtvObrigatorio}
                data-tipo={item.MtvTipo.toLowerCase()}
                data-variavel={item.variavel}
                label={item.MtvNomeCampo}
                type={item.MtvTipo.toLowerCase()}
                variant="outlined"
                className={classes.bloqueioInput}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: item.MtvTipo.toLowerCase() == "date" ? getCurrentDate() : undefined,
                }}
              />
            );
          });
          var el = document.querySelector('#bloqueioVars');
          ReactDOM.render(
            vars,
            el
          );
          el.style.display = 'grid';
        } else {
          document.querySelector('#bloqueioVars').innerHTML = "";
          console.log(`Não há variaveis (0x1)`);
        }
      };

      var result = await request.get(`${process.env.REACT_APP_API_LAMPZAP}/buscarTemplates?InNome=${process.env.REACT_APP_LOJA}&MttId=${MttId}&MtTipo=0`).catch(e => {
        toast.error(`Erro na conexão com a API (Erro ${e.response.status??'0x101'})`);
        setTimeout(() => setTelaTrava(false), 5000);
      });
      
      if(result.status == 200) {
        var dados = result.data;
        if(dados.status && dados.templates.length > 0) {
          var list = [];

          dados.templates.forEach((item, i) => {
            list.push(
              <MenuItem key={i} value={i}>{item.msgTemplate}</MenuItem>
            );
          });

          var element = (
            <>
              <div data-id="nome" className={classes.modalTop}><SvgIcon component={MttId == 1 ? ComputerIcon : (MttId == 2 ? RestaurantIcon : HelpIcon)}/> {MttId == 1 ? `Retorno de atendimento` : (MttId == 2 ? `Retorno do almoço` : `Retorno`)}</div>
              <div data-id="content" className={classes.contentTrava}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                >
                <InputLabel id="bloqueio-label">Escolha a mensagem a ser enviada</InputLabel>
                  <Select
                    className={classes.formSelect}
                    labelId="bloqueio-label"
                    label="Escolha a mensagem a ser enviada"
                    id="bloqueio-form"
                    onChange={processarVars}
                  >
                    {list}
                  </Select>
                </FormControl>
                <div className={classes.bloqueioVars} id="bloqueioVars"></div>
                <ButtonBase
                  className={clsx(classes.bloqueioBtn, "MuiButton-root")}
                  onClick={enviarRequisicao}
                  style={{
                    background: '#1976d2',
                    color: '#fff',
                  }}
                >
                  <SendIcon /> Enviar mensagem em lote
                </ButtonBase>
              </div>
            </>
          );

          ReactDOM.render(
            element,
            document.querySelector('#telaTrava')
          );
        } else {
          toast.error(`Não foi encontrado um plano pro MttId (Erro 0x103)`);
          setTimeout(() => setTelaTrava(false), 5000);
        }
      } else {
        toast.error(`Erro na conexão com a API (Erro 0x102)`);
        setTimeout(() => setTelaTrava(false), 5000);
      }
    };

    const cancelarEnvio = async () => {
      loader(true);

      var res = await api.put(`/BatchMessages/`, {
        userId: user.id,
      });

      if(res.status == 200) {
        localStorage.setItem("userLocked", JSON.stringify({
          locked: false,
          MttId: MttId,
        }));
      } else {
        toast.error(`Erro na conexão com a API (Erro ${res.status??'0x500'})`);
      }
    };

    console.log(timeLeft);
    if(open && timeLeft < 0) {
      obterVars();
    }

    return (
        <Drawer
          open={open}
        >
          <Fade
            in={!canCancel}
            style={{
              transitionDelay: '200ms',
            }}
          >
            <div id="telaTrava" className={classes.modal}></div>
          </Fade>
          <Fade
            in={canCancel}
            style={{
              transitionDelay: '200ms',
            }}
          >
            <div className={classes.modal}>
              <div className={classes.modalTop}><SvgIcon component={MttId == 1 ? ComputerIcon : (MttId == 2 ? RestaurantIcon : HelpIcon)}/> {MttId == 1 ? `Retorno de atendimento` : (MttId == 2 ? `Retorno do almoço` : `Retorno`)}</div>
              <div className={classes.contentTrava}>
                <div className={classes.travaTitle}>Você tem {timeLeft > 60 ? Math.floor(timeLeft / 60) + (timeLeft < 120 ? ' minuto' : ' minutos') : 'menos de 1 minuto'} para cancelar o envio:</div>
                <div className={classes.travaCount}>{fmtMSS(timeLeft)}</div>
                <div className={classes.menuTrava} onClick={cancelarEnvio}>
                  <div className={classes.menuTravaOpt}>Cancelar mensagem em lote</div>
                </div>
              </div> 
            </div>
          </Fade>
        </Drawer>
    );
};

export default TelaTrava;