import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import openSocket from "socket.io-client";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useAuth = () => {
	const history = useHistory();
	const [isAuth, setIsAuth] = useState(false);
	const [isLocked, setLocked] = useState(false);
	const [MttId, setMttId] = useState(0);
	const [tipoOrdenacao, settipoOrdenacao] = useState(false);
	const [lockDateIn, setLockDateIn] = useState(null);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});

	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			if (token) {
				config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
				setIsAuth(true);
			}
			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;

				const { data } = await api.post("/auth/refresh_token");
				console.log(data);
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					localStorage.setItem("userLocked", JSON.stringify(data.userLocked));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
				}
				return api(originalRequest);
			}
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				localStorage.removeItem("userLocked");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		const intervalId = setInterval(async () => {
			try {
				let lock = localStorage.getItem("userLocked");
				let json = JSON.parse(lock);
				if (lock !== null){
					if(json.locked){
						setLocked(true);
						setMttId(json.MttId)
						setLockDateIn(json.lockDateIn)
					} else {
						setLocked(false);
						setMttId(0)
						setLockDateIn(null)
					}
				}
			} catch(e) {
				console.log('index:78',e);
			}
			
		}, 2500);
	
		// Limpar o intervalo quando o componente for desmontado
		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		(async () => {
				try {
					const { data } = await api.post("/auth/refresh_token");
					if (data) {
						console.log(data);
						localStorage.setItem("token", JSON.stringify(data.token));
						localStorage.setItem("userLocked", JSON.stringify(data.userLocked));
						api.defaults.headers.Authorization = `Bearer ${data.token}`;
					}
					setIsAuth(true);
					setUser(data.user);
				} catch (err) {
					toastError(err);
				}
			setLoading(false);
		})();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("user", data => {
			if (data.action === "update" && data.user.id === user.id) {
				setUser(data.user);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

	const handleLogin = async userData => {
		setLoading(true);

		try {
			const { data } = await api.post("/auth/login", userData);
			localStorage.setItem("token", JSON.stringify(data.token));
			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			setUser(data.user);
			setIsAuth(true);
			toast.success(i18n.t("auth.toasts.success"));
			history.push("/tickets");
			setLoading(false);
			(async () => {
				try {
					const { data } = await api.post("/auth/refresh_token");
					if (data) {
						console.log(data);
						localStorage.setItem("userLocked", JSON.stringify(data.userLocked));
					}
				} catch (err) {
					toastError(err);
				}
		})();
			
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

	const handleLogout = async () => {
		setLoading(true);

		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			api.defaults.headers.Authorization = undefined;
			setLoading(false);
			history.push("/login");
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

	useEffect(() => {
		try {
			let lock = localStorage.getItem("userLocked");
			let json = JSON.parse(lock);
			settipoOrdenacao(json.tipoOrdenacao)
		} catch(e) {
			console.log('index:173',e);
		}
	})
	return { isAuth, user, loading, handleLogin, handleLogout, isLocked, MttId, tipoOrdenacao, setLoading, lockDateIn };
};

export default useAuth;
