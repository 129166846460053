import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import WebhookSelect from "../WebhookSelect";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

// const SessionSchema = Yup.object().shape({
// 	name: Yup.string()
// 		.min(2, "Too Short!")
// 		.max(50, "Too Long!")
// 		.required("Required")
// });

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const [selectedTipoConexao, setSelectedTipoConexao] = useState("");
	const initialState = {
		name: null,
		isDefault: false
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [selectedWebHookId, setSelectedWebHookId] = useState(null);
	const [isCloudApi, setIsCloudApi] = useState(false);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);
				setSelectedTipoConexao(data.tipoConexao)
				setSelectedWebHookId(data.webHookId)

				setIsCloudApi(data.tipoConexao === "cloudapi" ? true : false)

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		const whatsappData = { 
			...values, 
			queueIds: selectedQueueIds, 
			tipoConexao: selectedTipoConexao, 
			webHookId: selectedWebHookId 
		};

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleChangeTipoConexao = (e) => {
		setSelectedTipoConexao(e.target.value);

		if (e.target.value === "cloudapi") {
			setIsCloudApi(true)
		} else {
			setIsCloudApi(false)
			setWhatsApp({ 
				...whatsApp,
				metaToken: null,
				webhookVerifyToken: null,
				portWebHook: null,
				webhookPath: null
			});
		}
    };

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
		setSelectedTipoConexao("");
		setIsCloudApi(false)
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					// validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						console.log(values);
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label="Disparo"
									/>
								</div>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>
								<div style={{ marginTop: 6 }}>
									<FormControl fullWidth margin="dense" variant="outlined">
										<InputLabel>Tipo Conexão</InputLabel>
										<Select
											as={Select}
											label="Tipo Conexão"
											labelWidth={100}
											name="tipoConexao"
											error={touched.tipoConexao && Boolean(errors.tipoConexao)}
											helperText={touched.tipoConexao && errors.tipoConexao}
											value={selectedTipoConexao}
											onChange={handleChangeTipoConexao}
										>
											<MenuItem key="baileys" value="baileys">baileys</MenuItem>
											<MenuItem key="whatsapp-web.js" value="whatsapp-web.js">whatsapp-web.js</MenuItem>
											<MenuItem key="cloudapi" value="cloudapi">cloudapi</MenuItem>
										</Select>
									</FormControl>
								</div>
								<WebhookSelect
									onChange={id => setSelectedWebHookId(id)}
									selectedWebHookId={selectedWebHookId}
								/>

								{isCloudApi && (
									<div style={{ marginTop: 6 }}>
										<Field
											as={TextField}
											label="Número"
											autoFocus
											name="number"
											error={touched.number && Boolean(errors.number)}
											helperText={touched.number && errors.number}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth
										/>
										<Field
											as={TextField}
											label="Meta token"
											autoFocus
											name="metaToken"
											error={touched.metaToken && Boolean(errors.metaToken)}
											helperText={touched.metaToken && errors.metaToken}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth
										/>
										<Field
											as={TextField}
											label="Webhook Path"
											autoFocus
											name="webhookPath"
											error={touched.webhookPath && Boolean(errors.webhookPath)}
											helperText={touched.webhookPath && errors.webhookPath}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth
										/>
										<Field
											as={TextField}
											label="Webhook Verify Token"
											autoFocus
											name="webhookVerifyToken"
											error={touched.webhookVerifyToken && Boolean(errors.webhookVerifyToken)}
											helperText={touched.webhookVerifyToken && errors.webhookVerifyToken}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth
										/>
										<Field
											as={TextField}
											label="Port WebHook"
											autoFocus
											name="portWebHook"
											error={touched.portWebHook && Boolean(errors.portWebHook)}
											helperText={touched.portWebHook && errors.portWebHook}
											variant="outlined"
											margin="dense"
											className={classes.textField}
											fullWidth
										/>
									</div>
								)}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
