import React, { useState, useEffect, useRef, useContext } from "react";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  btnWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  contactBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    overflowY: "auto",
    cursor: "pointer",
  },
  contactAvatar: {
    marginRight: theme.spacing(2),
  },
}));

const ShareContactModal = ({ open, onClose, contact, ticketId }) => {
  const [sharableContracts, setSharableContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (open) {
      getSharableContacts();
    }
  }, [open]);

  const handleClose = () => {
    onClose();
  };

  const handleShareContact = async (contactId, ticketId, userId) => {
    setLoading(true);
    const payload = {
      contactId,
      ticketId,
	  	userId
    };
    try {
      await api.post(`/contacts/share`, payload);
      if (isMounted.current) {
        handleClose();
        toast.success(i18n.t("shareContactModal.success"));
      }
    } catch (err) {
      if (isMounted.current) {
        toastError(err);
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  const getSharableContacts = async () => {
    try {
      const { data } = await api.get(`/contacts?searchParam=isSharable=1`);
      setSharableContracts(data.contacts);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
        <DialogTitle id="form-dialog-title">
          {i18n.t("shareContactModal.title.share")}
        </DialogTitle>
        <Formik enableReinitialize={true}>
          {() => (
            <Form>
              <DialogContent dividers style={{ padding: 0, minWidth: "25vw" }}>
                {loading ? (
                  <Box display="flex" justifyContent="center" m={3}>
                    <CircularProgress />
                  </Box>
                ) : (
                  sharableContracts.map((contact, index) => (
                    <React.Fragment key={index}>
                      <Box
                        className={classes.contactBox}
                        onClick={() => handleShareContact(contact.id, ticketId, user.id)}
                      >
                        <Avatar
                          alt={contact.name}
                          src={contact.profilePicUrl}
                          className={classes.contactAvatar}
                        />
                        <Box flexGrow={1}>
                          <Box display="flex" alignItems="center">
                            <Typography variant="subtitle2">
                              {i18n.t("shareContactModal.form.name")}:
                            </Typography>
                            <Typography variant="body2" style={{ marginLeft: 4 }}>
                              {contact.name}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography variant="subtitle2">
                              {i18n.t("shareContactModal.form.number")}:
                            </Typography>
                            <Typography variant="body2" style={{ marginLeft: 4 }}>
                              {contact.number}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  ))
                )}
                <DialogActions style={{ margin: 10, justifyContent: "center" }}>
                  <Button onClick={handleClose} color="secondary" variant="outlined">
                    {i18n.t("shareContactModal.buttons.cancel")}
                  </Button>
                </DialogActions>
              </DialogContent>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default ShareContactModal;
