import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import {
    Formik,
    Form,
    Field
} from "formik";
import { toast } from "react-toastify";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField
} from "@material-ui/core";

import { Colorize } from "@material-ui/icons";
import { ColorBox } from 'material-ui-color';
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const ReasonSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, "Mensagem muito curta")
        .required("Obrigatório")
});

const ReasonModal = ({ open, onClose, reasonId, reload }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);

    const initialState = {
        name: "",
        color: ""
    };

    const [reason, setReason] = useState(initialState);

    useEffect(() => {
        try {
            (async () => {
                if (!reasonId) return;

                const { data } = await api.get(`/reasons/${reasonId}`);
                setReason(prevState => {
                    return { ...prevState, ...data };
                });
            })()
        } catch (err) {
            toastError(err);
        }
    }, [reasonId, open]);

    const handleClose = () => {
        setReason(initialState);
        setColorPickerModalOpen(false);
        onClose();
    };

    const handleSaveReason = async values => {
        const reasonData = { ...values, userId: user.id };
        try {
            if (reasonId) {
                await api.put(`/reasons/${reasonId}`, reasonData);
            } else {
                await api.post("/reasons", reasonData);
            }
            toast.success(i18n.t("reasonModal.success"));
            if (typeof reload == 'function') {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {(reasonId ? `${i18n.t("reasonModal.title.edit")}` : `${i18n.t("reasonModal.title.add")}`)}
                </DialogTitle>
                <Formik
                    initialValues={reason}
                    enableReinitialize={true}
                    validationSchema={ReasonSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveReason(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label={i18n.t("reasonModal.form.name")}
                                        name="name"
                                        error={touched.name && Boolean(errors.name)}
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        onChange={(e) => setReason(prev => ({ ...prev, name: e.target.value }))}
                                        fullWidth
                                    />
                                </div>
                                <br />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("reasonModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {reasonId
                                        ? `${i18n.t("reasonModal.buttons.okEdit")}`
                                        : `${i18n.t("reasonModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default ReasonModal;