import React, { useState, useEffect, lazy } from "react";
import { makeStyles, Avatar, CardHeader, Button } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles((theme) => ({
  buttonBadge: {
		padding: '0px 6px',
		borderRadius: 10,
		fontSize: "0.9em",
		marginLeft: '5px',
    '& span': {
      pointerEvents: 'none'
    },
  },
  dropDownBadge: {
    padding: '0px 6px',
    margin: '5px',
    fontSize: "1.0em",
    marginRight: '5px'
  }
}));

const TagsLazy = lazy(() => import(`../TagDropDown`))
const ProductsLazy = lazy(() => import(`../ProductDropDown`))

const TicketInfo = ({ contact, ticket, onClick }) => {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [products, setProducts] = useState([]);
  const [anchorElTag, setAnchorElTag] = useState(null);
  const [anchorElProduct, setAnchorElProduct] = useState(null);
  const openTag = Boolean(anchorElTag);
  const openProduct = Boolean(anchorElProduct);
  const [Tag, setTag] = useState({});
  const [Product, setProduct] = useState({});

  const handleClickTag = (e) => {
    setAnchorElTag(e.currentTarget);
	  e.stopPropagation();
  };

  const handleCloseTag = () => {
    setAnchorElTag(null);
  };

  const handleClickProduct = (e) => {
    setAnchorElProduct(e.currentTarget);
	  e.stopPropagation();
  };

  const handleCloseProduct = () => {
    setAnchorElProduct(null);
  };

  useEffect(() => {
    let isComponentMounted = true;

    const fetchData = async () => {
      try {
        const getTags = await api.get("/tags");
        const getProducts = await api.get("/products");

        if (isComponentMounted) {
          setTags(getTags.data.tags);
          setProducts(getProducts.data.products);
        }
      } catch (error) {
        toastError(error);
      }
    };

    fetchData();

    return () => {
      isComponentMounted = false;
    };
  }, []);

  useEffect(() => {
    if (tags.length > 0 && ticket.tagId != null) {
      setTag(tags.find(tag => tag.id === ticket.tagId));
    }

    if (products.length > 0 && ticket.productId != null) {
      setProduct(products.find(product => product.id === ticket.productId));
    }
  }, [ticket, tags, products])

  const calcularCorDoTexto = (cor) => {
    const r = parseInt(cor.slice(1, 3), 16);
    const g = parseInt(cor.slice(3, 5), 16);
    const b = parseInt(cor.slice(5, 7), 16);
    const luminosidade = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    return luminosidade > 0.7 ? 'black' : 'white';
  };


  return (
    <CardHeader
      onClick={onClick}
      style={{ cursor: "pointer" }}
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
      /* id={'anchorPosition'} */
      title={
        <>
          {contact.name} #{ticket.id}
        </>
      }
      subheader={
        <>
            {
              ticket.isGroup ? 'Visão geral (Grupo)' : (
                ticket.user &&
                `Responsável: ${ticket.user.name} [${ticket.whatsapp != null ? `${ticket.whatsapp.name} - ${ticket.whatsapp.number}` : `Nenhuma linha`}]`)
            }

          <div>
          {(ticket.status !== 'pending' && !ticket.isGroup) && (
              <Button

                  onClick={(e)=>{e.stopPropagation();handleClickProduct(e); }}
                  className={classes.buttonBadge}
                  style={{
                    backgroundColor: Product?.color,
                    color: calcularCorDoTexto(Product?.color || '#E5F417')
                  }}
                  aria-haspopup="true"
                  anchorReference="anchorPosition"
                >
                  {Product?.name || "..."}
              </Button>
            )}

            {(ticket.status !== 'pending' && !ticket.isGroup) && (

              <Button

                  onClick={(e)=>{e.stopPropagation();handleClickTag(e); }}
                  className={classes.buttonBadge}
                  style={{
                    backgroundColor: Tag?.color,
                    color: calcularCorDoTexto(Tag?.color || '#E5F417')
                  }}
                  aria-haspopup="true"
                  anchorReference="anchorPosition"
                >
                  {Tag?.name || "..."}
              </Button>
            )}
            </div>

            {openTag && <React.Suspense fallback={'...Carregando'}><TagsLazy ticket = {ticket} openTag={openTag} anchorElTag={anchorElTag} setAnchorElTag={setAnchorElTag} handleClickTag={handleClickTag} handleCloseTag={handleCloseTag} tagList={tags}/></React.Suspense>}

            {openProduct && <React.Suspense fallback={'...Carregando'}><ProductsLazy ticket = {ticket} openProduct={openProduct} anchorElProduct={anchorElProduct} setAnchorElProduct={setAnchorElProduct} handleClickProduct={handleClickProduct} handleCloseProduct={handleCloseProduct} productList={products}/></React.Suspense>}

        </>

        
      }
    />
  );
};

export default TicketInfo;