import React, { useState, useEffect, useContext } from "react";

import * as Yup from "yup";
import {
    Formik,
    Form,
    Field
} from "formik";
import { toast } from "react-toastify";

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    TextField
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const AgendamentoSchema = Yup.object().shape({
    text: Yup.string()
        .min(10, "Mensagem muito curta")
        .required("Obrigatório")
});

const AgendamentoModal = ({ open, onClose, ageId, ticketId, reload }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);

    const initialState = {
        text: "",
        ticketId: ticketId,
        status: "pending",
        sendDate: ""
    };

    const [agendamento, setAgendamento] = useState(initialState);

    useEffect(() => {
        try {
            (async () => {
                if (!ageId) return;

                const { data } = await api.get(`/agendamentos/${ageId}`);
                setAgendamento(prevState => {
                    return { ...prevState, ...data };
                });
            })()
        } catch (err) {
            toastError(err);
        }
    }, [ageId, open]);

    const handleClose = () => {
        setAgendamento(initialState);
        onClose();
    };

    const handleSaveAgendamento = async values => {
        const agendamentoData = { ...values, userId: user.id };
        try {
            if (ageId) {
                await api.put(`/agendamentos/${ageId}`, agendamentoData);
            } else {
                await api.post("/agendamentos", agendamentoData);
            }
            toast.success(i18n.t("agendamentoModal.success"));
            if (typeof reload == 'function') {
                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {(ageId ? `${i18n.t("agendamentoModal.title.edit")}` : `${i18n.t("agendamentoModal.title.add")}`)}
                </DialogTitle>
                <Formik
                    initialValues={agendamento}
                    enableReinitialize={true}
                    validationSchema={AgendamentoSchema}
                    
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveAgendamento(values);
                            actions.setSubmitting(false);
                        }, 400);
                    }}
                >
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Texto do Agendamento"
                                        name="text"
                                        error={touched.text && Boolean(errors.text)}
                                        helperText={touched.text && errors.text}
                                        variant="outlined"
                                        margin="dense"
                                        defaultValue={`${`Olá, bom dia [NOME]\nEstou passando aqui para confirmar seu *agendamento para hoje*.\nPor se tratar de um Projeto Social com vagas limitadas, peço para que caso não for possível comparecer a consulta nos avise, sendo assim, disponibilizamos a data para um outro beneficiário. Posso confirmar sua presença?`}`}
                                        multiline
                                        rows={6}
										fullWidth
                                    />

                                </div>
                                <br />
                                  <Field
                                        as={TextField}
                                        name="sendDate"
                                        error={touched.sendDate && Boolean(errors.sendDate)}
                                        helperText={touched.sendDate && errors.sendDate}
                                        type="datetime-local"
                                        variant="outlined"
                                        margin="dense"
										fullWidth
                                    />
                            </DialogContent>

                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined"
                                >
                                    {i18n.t("agendamentoModal.buttons.cancel")}
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}
                                >
                                    {ageId
                                        ? `${i18n.t("agendamentoModal.buttons.okEdit")}`
                                        : `${i18n.t("agendamentoModal.buttons.okAdd")}`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default AgendamentoModal;