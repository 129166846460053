import React, { useState, useEffect, useContext } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import { i18n } from "../../translate/i18n";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    maxWidth: {
        width: "100%",
    },
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	btnWrapper: {
		position: "relative",
	}
}));

const CloseTicketModal = ({ open, onClose, onSave, ticketId, status }) => {
	const classes = useStyles();
    const [reasons, setReasons] = useState([]);
	const [selectedReason, setSelectedReason] = useState();
    const { user } = useContext(AuthContext);
    const userId = user.id;

	useEffect(() => {
		const fetchReasons = async () => {
			try {
				const { data } = await api.get(`/reasons`);

                setReasons(data.reasons);
			} catch (err) {
				toastError(err);
			}
		};

		fetchReasons();
	}, [open]);

	const handleClose = () => {
        onClose();
		setReasons(reasons);
	};

	const handleSaveTicketHandling = async e => {
        e.preventDefault();
        if (!ticketId || !selectedReason) return;

		try {
            const data = {
                ticketId,
                reasonId: selectedReason,
                userId,
                endDate: new Date()
            }
            
            await api.put(`/ticketHandling`, data);

            if (onSave) {
                onSave(data);
            }

            await api.put(`/tickets/${ticketId}`, {
                status,
                userId,
            });

            handleClose();
    
		} catch (err) {
			toastError(err);
		}
	};

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
                <form onSubmit={handleSaveTicketHandling}>
                    <DialogTitle id="form-dialog-title">
                        {i18n.t("closeTicketModal.title")}
                    </DialogTitle>
                    <DialogContent dividers>
                        <FormControl variant="outlined" className={classes.maxWidth}>
                            <InputLabel>{i18n.t("closeTicketModal.fieldLabel")}</InputLabel>
                            <Select
                                value={selectedReason}
                                onChange={(e) => setSelectedReason(e.target.value)}
                                label={i18n.t("closeTicketModal.fieldLabel")}
                            >
                                {
                                    reasons.map((reason) => {
                                        if (reason.id !== 1 && reason.id !== 2) {
                                            return (
                                                <MenuItem key={reason.id} value={reason.id}>{reason.name}</MenuItem>
                                            )
                                        }
                                    })
                                }
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            color="secondary"
                            variant="outlined"
                        >
                            {i18n.t("closeTicketModal.buttons.cancel")}
                        </Button>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            className={classes.btnWrapper}
                        >
                            {i18n.t("closeTicketModal.buttons.ok")}
                        </Button>
                    </DialogActions>
                </form>
		    </Dialog>
        </div>
	);
};

export default CloseTicketModal;
