import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Products from "../pages/Products/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import Queues from "../pages/Queues/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import QuickAnswers from "../pages/QuickAnswers/";
import Tags from "../pages/Tags";
import Reasons from "../pages/Reasons";
import Agendamentos from "../pages/Agendamentos";

import Route from "./Route";
import Assingment from "../pages/Assingment";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} freeAccess />
          {/* <Route exact path="/signup" component={Signup} /> */}
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/tickets/:ticketId?" component={Tickets} />
              <Route exact path="/contacts" component={Contacts} />
              <Route exact path="/Agendamentos" component={Agendamentos} />

              <Route exact path="/connections" component={Connections} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route exact path="/assingment" component={Assingment} isPrivate />
              <Route exact path="/quickAnswers" component={QuickAnswers} isPrivate />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
              <Route exact path="/Tags" component={Tags} isPrivate />
              <Route exact path="/Products" component={Products} isPrivate />
              <Route exact path="/Reasons" component={Reasons} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;