import React, { useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { ListItemText, Checkbox } from "@material-ui/core";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const TicketsTagSelect = ({
	selectedTagIds = [],
	onChange,
}) => {
	const handleChange = e => {
		onChange(e.target.value);
	};

    const [tags, setTags] = useState([]);

   useEffect(() => {(async () => {
            try {
                const { data } = await api.get("/tags");
                setTags(data.tags);
            } catch (err) {
                toastError(err);
            }
        })();
    }, []); 

	return (
		<div style={{ width: 107, marginTop: -4, marginLeft: 6 }}>
			<FormControl fullWidth margin="dense">
				<Select
					displayEmpty
					variant="outlined"
					value={selectedTagIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={() => {
						if (selectedTagIds.length == 0) {
							return i18n.t("ticketsTagSelect.placeholder")
						} else {
							const tag = tags.filter(function(tag) {
								return (tag.id == selectedTagIds)
							})[0];

							return (<span style={{ color: tag?.color }}>{ tag?.name }</span>) ?? "Sem filtro";
						}
					}}
				>
					{tags?.length > 0 &&
						tags.map(tag => (
							selectedTagIds.includes(tag.id) == false &&
							<MenuItem dense key={tag.id} value={tag.id}>
								 <Checkbox
									style={{
										color: tag.color,
										backgroundColor: tag.color,
										width: "3px",
										height: "3px",
										borderRadius: "40%",
										marginRight: "0.5em",
									}}
									size="small"
									color="primary"
								/>

								<ListItemText primary={tag.name}/>
							</MenuItem>
						))}

						{
                            selectedTagIds != 0 && 

                            <MenuItem dense value={0}>
                                <Checkbox
									style={{
										color: "#000000",
										backgroundColor: "#000000",
										width: "3px",
										height: "3px",
										borderRadius: "40%",
										marginRight: "0.5em",
									}}
									size="small"
									color="primary"
								/>
								<ListItemText primary={"Sem Filtro"}/>
							</MenuItem>
                        }
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsTagSelect;
