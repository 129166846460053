import { useState, useEffect } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const useTickets = ({
    searchParam,
    pageNumber,
    status,
    date,
    showAll,
    queueIds,
    tagIds,
    productIds,
    filtros,
    tab,
    withUnreadMessages,
}) => {
    const [loading, setLoading] = useState(true);
    const [hasMore, setHasMore] = useState(false);
    const [tickets, setTickets] = useState([]);
    const [count, setCount] = useState(0);


    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {

            setTickets([]);

            if(tab === "search" && filtros.length === 0){
                setTickets([])
                setCount(0)
                setLoading(false)
            }else{
                const fetchTickets = async() => {
                    try {
                        const { data } = await api.get("/tickets", {
                            params: {
                                searchParam,
                                pageNumber,
                                status,
                                date,
                                showAll,
                                queueIds,
                                tagIds,
                                productIds,
                                filtros,
                                withUnreadMessages,
                            },
                        })
                        setTickets(data.tickets)
    
    
                        setHasMore(data.hasMore)
                        setCount(data.count)
                        setLoading(false)
                    } catch (err) {
                        setLoading(false)
                        toastError(err)
                    }
                }
    
                const closeTicket = async(ticket) => {
                    await api.put(`/tickets/${ticket.id}`, {
                        status: "closed",
                        userId: ticket.userId || null,
                    })
                }
    
                fetchTickets()
            }

        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [
        searchParam,
        pageNumber,
        status,
        date,
        showAll,
        queueIds,
        tagIds,
        productIds,
        filtros,
        withUnreadMessages,
    ])

    return { tickets, loading, hasMore, count };
};

export default useTickets;