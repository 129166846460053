import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  AddCircleOutline,
  DeleteOutline,
  Edit,
  Search
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ProductModal from "../../components/ProductModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_PRODUCTS") {
    const products = action.payload;
    const newProducts = [];

    products.forEach((product) => {
      const productIndex = state.findIndex((s) => s.id === product.id);
      if (productIndex !== -1) {
        state[productIndex] = product;
      } else {
        newProducts.push(product);
      }
    });

    return [...state, ...newProducts];
  }

  if (action.type === "UPDATE_PRODUCTS") {
    const product = action.payload;
    const productIndex = state.findIndex((s) => s.id === product.id);

    if (productIndex !== -1) {
      state[productIndex] = product;
      return [...state];
    } else {
      return [product, ...state];
    }
  }

  if (action.type === "DELETE_PRODUCT") {
    const productId = action.payload;

    const productIndex = state.findIndex((s) => s.id === productId);
    if (productIndex !== -1) {
      state.splice(productIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Products = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deletingProduct, setDeletingProduct] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [products, dispatch] = useReducer(reducer, []);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const fetchProducts = useCallback(async () => {
    try {
      const { data } = await api.get("/products/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_PRODUCTS", payload: data.products });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchProducts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchProducts]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("products", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PRODUCTS", payload: data.products });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PRODUCTS", payload: +data.productId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenProductModal = () => {
    setSelectedProduct(null);
    setProductModalOpen(true);
  };

  const handleCloseProductModal = () => {
    setSelectedProduct(null);
    setProductModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setProductModalOpen(true);
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/products/${productId}`);
      toast.success(i18n.t("products.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchProducts();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          `${i18n.t("products.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteProduct(deletingProduct.id)
        }
      >
        {
          `${i18n.t("products.confirmationModal.deleteMessage")}`
        }
      </ConfirmationModal>
      <ProductModal
        open={productModalOpen}
        onClose={handleCloseProductModal}
        reload={fetchProducts}
        aria-labelledby="form-dialog-title"
        productId={selectedProduct && selectedProduct.id}
      />
      <MainHeader>
        <Title >{i18n.t("products.title")} ({products.length})</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("products.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("products.table.color")}</TableCell>
              <TableCell align="center">{i18n.t("products.table.proId")}</TableCell>
              <TableCell align="center">{i18n.t("products.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell align="center">
                    {product.name}
                  </TableCell>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <span
                        style={{
                          backgroundColor: product.color,
                          width: 60,
                          height: 20,
                          alignSelf: "center",
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    {product.proId}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditProduct(product)}
                      disabled={product.id == 1 ? disabled : enabled }
                    >
                      <Edit
                        color={product.id === 1 ? 'primary' : 'secondary'}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Products;