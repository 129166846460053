import React, { useEffect, useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

const WebhookSelect = ({ selectedWebHookId, onChange }) => {
	const [webhooks, setWebhooks] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/webhook");
				setWebhooks(data.webhooks);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div style={{ marginTop: 6 }}>
			<FormControl fullWidth margin="dense" variant="outlined">
				<InputLabel>Webhook</InputLabel>
				<Select
					labelWidth={70}
					value={selectedWebHookId}
					onChange={handleChange}
					name="webHookId"
				>
					<MenuItem key={null} value={null}>
						Nenhum
					</MenuItem>
					{webhooks.map(webhook => (
						<MenuItem key={webhook.id} value={webhook.id}>
							{webhook.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default WebhookSelect;
