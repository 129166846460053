import React, { useState, useEffect, useRef, useReducer } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast, } from "react-toastify";

import {
  makeStyles,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Select,
  CircularProgress,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";

import api from "../../services/api";
import toastError from "../../errors/toastError";


const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
  },
  textField: {
    marginRight: theme.spacing(1),
    width: "100%",
  },
  multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

  textFieldDuplo: {
    marginRight: theme.spacing(1),
    width: "50%",
  },
  btnWrapper: {
    position: "relative",
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  textQuickAnswerContainer: {
    width: "100%",
  },
  bloqueioModalTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "20px",
    color: "#fff",
    background: "#2f3337",
    fontSize: "22px",
    minWidth: "580px",
    textAlign: "center",
  },
}));

const TemplateModal = ({
    open,
    onClose,
    templates,
    textoFiltro,
    handleSearchSubmit,
    onSave,
}) => {
  const classes = useStyles();
  const isMounted = useRef(true);


  const handleClose = () => {
    onClose();
  };

const initialValues = {
  "template": null
};


 return (
        <div className={classes.root}>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
            scroll="paper"
          >
            <DialogTitle id="form-dialog-title" className={classes.bloqueioModalTop}>
               Opções de template
            </DialogTitle>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values, actions) => {
                setTimeout(() => {
                  actions.setSubmitting(true);
                  const selectedTemplate = values.template; // Obter o valor selecionado

                  const selectedTemplateData = templates.find(template => template.msgNome === selectedTemplate);

                  const selectedTemplateText = selectedTemplateData?.msgTemplate; // Obter o texto da opção selecionada
                  const interactionTypeId = selectedTemplateData?.msgTipo; // Obter o valor selecionado
                  
                  handleSearchSubmit(values, selectedTemplateText, interactionTypeId);
                  handleClose();
                  actions.setSubmitting(false);
                }, 400);
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form>
                  <DialogContent dividers>
                  <Typography variant="h6" component="h6">
                    Selecione o tipo de mensagem a ser enviada
                  </Typography>
                    <div className={classes.textQuickAnswerContainer}>
                      <Field
                        margin="dense"
                        variant="outlined"
                        native
                        fullWidth
                        autoFocus
                        name="template"
                        as={Select}                
                      >
                      
                              <option></option>
                              {templates.map((template) => (
                                <option key={template.msgNome} value={template.msgNome}>{template.msgTemplate}</option>
                              ))}
                          
                      </Field>
                      
                    </div>               
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleClose}
                      color="secondary"
                      disabled={isSubmitting}
                      variant="outlined"
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={isSubmitting}
                      variant="contained"
                      className={classes.btnWrapper}
                    >
                      Enviar
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>
        </div>
      );
}
export default TemplateModal;
