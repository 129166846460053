import React, { useContext } from "react";
import { Route as RouterRoute, Redirect, useLocation } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, freeAccess = false, isPrivate = false, ...rest }) => {
  const { isAuth, loading, user } = useContext(AuthContext);
  const location = useLocation();

  if (!isAuth && !freeAccess) {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/login" }} />
      </>
    );
  }

  if (
    isAuth
    && isPrivate
    && location.pathname === rest.path
    && user.profile === 'user') {
    return (
      <>
        {loading && <BackdropLoading />}
        <Redirect to={{ pathname: "/tickets" }} />
      </>
    );
  }

  return (
    <>
      {loading && <BackdropLoading />}
      <RouterRoute {...rest} component={Component} />
    </>
  );
};

export default Route;