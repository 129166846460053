import React, { useContext } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";

import { SpyModeContext } from "../../context/SpyMode/SpyModeContext";

import { AuthContext } from "../../context/Auth/AuthContext";


import api from "../../services/api";

import toastError from "../../errors/toastError";


const TicketHandlingModal = ({ open, ticket, onClose }) => {
  const { user } = useContext(AuthContext);
  const { setSpyMode } = useContext(SpyModeContext);

  const handleUpdateTicket = async (status) => {
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: user.id || null,
      });
      onClose();
    } catch (err) {
      setSpyMode(true);
      toastError(err);
      onClose();
    }
  };

  if (user.profile === "user") {
    return (
      <Dialog open={open} maxWidth="lg" scroll="paper">
        {ticket?.contact?.isBlocked ? (
          <>
            <DialogTitle>Esse contato está bloqueado!</DialogTitle>
            <DialogActions>
              <Box textAlign="center" width="100%">
                <Button
                  onClick={() => {
                    setSpyMode(true);
                    onClose();
                  }}
                  color="secondary"
                  variant="outlined"
                  style={{ marginRight: 8 }}
                >
                  Modo visualização
                </Button>
              </Box>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Você realmente deseja iniciar essa conversa?</DialogTitle>
            <DialogActions>
              <Box textAlign="center" width="100%">
                <Button
                  onClick={() => {
                    setSpyMode(true);
                    onClose();
                  }}
                  color="primary"
                  variant="outlined"
                  style={{ marginRight: 8 }}
                >
                  Modo visualização
                </Button>
                <Button
                  onClick={() => {
                    setSpyMode(false);
                    handleUpdateTicket("open");
                    onClose();
                  }}
                  color="primary"
                  variant="contained"
                >
                  Iniciar conversa
                </Button>
              </Box>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  }
  return null;
};

export default TicketHandlingModal;