import React, { useState, useEffect, useContext, useRef } from "react";
import "emoji-mart/css/emoji-mart.css";
import { useParams } from "react-router-dom";
import { Picker } from "emoji-mart";
import MicRecorder from "mic-recorder-to-mp3";
import clsx from "clsx";
import TemplateModal from '../TemplateModal';
import ShareContactModal from '../ShareContactModal';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Fade from "@material-ui/core/Fade";
import InputBase from "@material-ui/core/InputBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MoreVert from "@material-ui/icons/MoreVert";
import MoodIcon from "@material-ui/icons/Mood";
import AddIcon from '@material-ui/icons/Add';
import PersonIcon from '@material-ui/icons/PersonRounded';
import SendIcon from "@material-ui/icons/Send";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import MicIcon from "@material-ui/icons/Mic";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ChatIcon from '@material-ui/icons/Chat'
import {
  Box,
  DialogTitle,
  Hidden,
  Menu,
  MenuItem,
  Typography
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api, { request } from "../../services/api";
import RecordingTimer from "./RecordingTimer";
import { ReplyMessageContext } from "../../context/ReplyingMessage/ReplyingMessageContext";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import openSocket from "socket.io-client";

import { SpyModeContext } from "../../context/SpyMode/SpyModeContext";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    background: "#eee",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: 0,
      width: "100%",
    },
  },

  newMessageBox: {
    position: "relative",
    background: "#eee",
    width: "100%",
    display: "flex",
    padding: "7px",
    alignItems: "center",
  },

  blockedMessageBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(238, 238, 238, .8)",
    zIndex: 1,
  },

  blockedMessageBtn: {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  messageInputWrapper: {
    padding: 6,
    marginRight: 7,
    background: "#fff",
    display: "flex",
    borderRadius: 20,
    flex: 1,
    position: "relative",
  },

  messageInput: {
    paddingLeft: 10,
    flex: 1,
    border: "none",
  },

  sendMessageIcons: {
    color: "grey",
  },

  uploadInput: {
    display: "none",
  },

  viewMediaInputWrapper: {
    display: "flex",
    padding: "10px 13px",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#eee",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
  },

  emojiBox: {
    position: "absolute",
    bottom: 63,
    width: 40,
    borderTop: "1px solid #e8e8e8",
  },
  
  shareContainer: {
    position: "relative",
  },
  
  shareBox: {
    background: "#eee",
    position: "absolute",
    bottom: '50px',
    borderTop: "1px solid #e8e8e8",
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    width: "max-content",
    boxShadow: "0 2px 2px #b3b3b3",
  },

  shareWrapper: {
    fontSize: '50px',
    display: 'flex',
    alignItems: 'flex-end',
    margin: '20px',
  },

  shareIcon: {
    borderRadius: '50%',
    marginRight: '10px',
    padding: 0,
    color: 'DodgerBlue',
    textAlign: "end",
  },

  rotateIcon: {
    transition: "transform 0.3s ease-in-out",
    transform: "rotate(0deg)",
  },

  rotateIconActive: {
    transform: "rotate(135deg)",
  },

  circleLoading: {
    color: green[500],
    opacity: "70%",
    position: "absolute",
    top: "20%",
    left: "50%",
    marginLeft: -12,
  },

  audioLoading: {
    color: green[500],
    opacity: "70%",
  },

  recorderWrapper: {
    display: "flex",
    alignItems: "center",
    alignContent: "middle",
  },

  cancelAudioIcon: {
    color: "red",
  },

  sendAudioIcon: {
    color: "green",
  },

  replyginMsgWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 8,
    paddingLeft: 73,
    paddingRight: 7,
  },

  replyginMsgContainer: {
    flex: 1,
    marginRight: 5,
    overflowY: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  replyginMsgBody: {
    padding: 10,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  replyginContactMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  replyginSelfMsgSideColor: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },
  messageQuickAnswersWrapper: {
    margin: 0,
    position: "absolute",
    bottom: "50px",
    background: "#ffffff",
    padding: "2px",
    border: "1px solid #CCC",
    left: 0,
    width: "100%",
    "& li": {
      listStyle: "none",
      "& a": {
        display: "block",
        padding: "8px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxHeight: "32px",
        "&:hover": {
          background: "#F1F1F1",
          cursor: "pointer",
        },
      },
    },
  }
}));

const MessageInput = ({ contact, ticketStatus, ticket }) => {
  const classes = useStyles();
  const { ticketId } = useParams();
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [shareContactModalOpen, setShareContactModalOpen] = useState(false);
  const [primeiroContato, setPrimeiroContato] = useState(false)
  const [medias, setMedias] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [showEmoji, setShowEmoji] = useState(false);
  const [showShareOptions, setshowShareOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recording, setRecording] = useState(false);
  const [quickAnswers, setQuickAnswer] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [disableTemplate, setDisableTemplate] = useState(false);
  const [mostrarBtnTemplate, setMostrarBtnTemplate] = useState(false);
  const [enviaTemplatePrimeiroContato, setEnviaTemplatePrimeiroContato] = useState(false);
  const [mostrarTemplate24h, setMostrarTemplate24h] = useState(((new Date() - new Date(ticket.lastMessageUser)) / (1000 * 60 * 60)) >= 24 && ticket.whatsapp?.tipoConexao === "cloudapi");
  const [typeBar, setTypeBar] = useState(false);
  const inputRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReplyingMessage, replyingMessage } = useContext(ReplyMessageContext);
  const { user } = useContext(AuthContext);

  const { spyMode, setSpyMode } = useContext(SpyModeContext);

  const [signMessage, setSignMessage] = useState(true);
  const [mostrarBlocked, setMostrarBlocked] = useState(0);
  const [mostrarIniciarConversa, setMostrarIniciarConversa] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, [replyingMessage]);

  useEffect(() => {
    setSpyMode(contact.isBlocked || ticket?.status == "closed")
    setMostrarBlocked(contact.isBlocked == 1)
  }, [contact]);

  useEffect(() => {
    setMostrarBlocked(contact.isBlocked == 1)
    setMostrarIniciarConversa((user.profile !== "user" && spyMode && contact.isBlocked == 0))
  }, [spyMode, ticket]);

  useEffect(() => {
    inputRef.current.focus();
    return () => {
      setInputMessage("");
      setShowEmoji(false);
      setMedias([]);
      setReplyingMessage(null);
    };
  }, [ticketId, setReplyingMessage]);

  const handleUpdateTicket = async (status) => {
    try {
      await api.put(`/tickets/${ticket.id}`, {
        status: status,
        userId: user.id || null,
      });
    } catch (err) {
      setSpyMode(true);
      toastError(err);
    }
  };

  useEffect(() => {
    if (!ticket.lastInteractionDate && ticket.status === "open" && !mostrarIniciarConversa && !spyMode && ticket.whatsapp?.tipoConexao === "cloudapi") setMostrarBtnTemplate(true)
    if (!ticket.lastMessageUser && ticket.whatsapp?.tipoConexao == "cloudapi") setEnviaTemplatePrimeiroContato(true);
    setMostrarTemplate24h(((new Date() - new Date(ticket.lastMessageUser)) / (1000 * 60 * 60)) >= 24 && ticket.status === "open" && ticket.whatsapp?.tipoConexao === "cloudapi")

  }, [ticket, mostrarIniciarConversa, enviaTemplatePrimeiroContato, mostrarBlocked]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("connect", () => socket.emit("joinChatBox", ticketId));

    socket.on("appMessage", (data) => {
      if (data.action === "create") {
        if (!data.message.fromMe && data.ticket.whatsapp?.tipoConexao == "cloudapi") {
          setMostrarTemplate24h(((new Date() - new Date(data.ticket.lastMessageDate)) / (1000 * 60 * 60)) >= 24 && ticket.whatsapp?.tipoConexao === "cloudapi")
          setMostrarBtnTemplate(false)
        }
      }
    });


    return () => {
      socket.disconnect();
    };
  }, [ticketId]);


  const handleChangeInput = (e) => {
    setInputMessage(e.target.value);
    handleLoadQuickAnswer(e.target.value);
  };

  const handleQuickAnswersClick = (value) => {
    setInputMessage(value);
    setTypeBar(false);
  };

  const handleAddEmoji = (e) => {
    let emoji = e.native;
    setInputMessage((prevState) => prevState + emoji);
  };

  const handleLoadQuickAnswer = async (value) => {
    if (value && value.indexOf("/") === 0) {
      try {
        const { data } = await api.get("/quickAnswers/", {
          params: { searchParam: inputMessage.substring(1) },
        });
        setQuickAnswer(data.quickAnswers);
        if (data.quickAnswers.length > 0) {
          setTypeBar(true);
        } else {
          setTypeBar(false);
        }
      } catch (err) {
        setTypeBar(false);
      }
    } else {
      setTypeBar(false);
    }
  };

  const handleChangeMedias = (e) => {
    if (!e.target.files) {
      return;
    }

    const selectedMedias = Array.from(e.target.files);
    setMedias(selectedMedias);
  };

  const handleInputPaste = (e) => {
    if (e.clipboardData.files[0]) {
      setMedias([e.clipboardData.files[0]]);
    }
  };

  const handleUploadMedia = async (e) => {
    setLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("fromMe", true);
    medias.forEach((media) => {
      formData.append("medias", media);
      formData.append("body", media.name);
    });

    try {
      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setLoading(false);
    setMedias([]);
  };

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      type: "text",
      template: null,
      bodySemNome: inputMessage.trim(),
      body: signMessage
        ? `*${user?.name}:*\n${inputMessage.trim()}`
        : inputMessage.trim(),
      quotedMsg: replyingMessage,
    };
    try {
      await api.post(`/messages/${ticketId}`, message);
    } catch (err) {
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await Mp3Recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  };

  const handleUploadAudio = async () => {
    setLoading(true);
    try {
      const [, blob] = await Mp3Recorder.stop().getMp3();
      if (blob.size < 10000) {
        setLoading(false);
        setRecording(false);
        return;
      }

      const formData = new FormData();
      const filename = `${new Date().getTime()}.mp3`;
      formData.append("medias", blob, filename);
      formData.append("body", filename);
      formData.append("fromMe", true);

      await api.post(`/messages/${ticketId}`, formData);
    } catch (err) {
      toastError(err);
    }

    setRecording(false);
    setLoading(false);
  };

  const handleCancelAudio = async () => {
    try {
      await Mp3Recorder.stop().getMp3();
      setRecording(false);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event) => {
    setAnchorEl(null);
  };

  const openTemplateModal = async (ticket, enviaTemplatePrimeiroContato = false) => {
    // const { data, setData } = useState({});

    // useEffect( () => {
    //   async getTicket
    //   setData(await api.get("/tickets/" + ticketId))
    // }, [ticket])

    if (ticket) {
      //Validar se o cliente não enviou nenhuma mensagem nesse meio tempo
      let bloqueado = (((new Date() - new Date(ticket.lastMessageUser)) / (1000 * 60 * 60)) >= 24 && (ticket.whatsapp ? (ticket.whatsapp.tipoConexao == "cloudapi" ? true : false) : false));
      if (!bloqueado) {
        toast.success(`Seu ticket foi desbloqueado por conta da ativade do cliente.`);
        window.location.reload();
        return;
      }
    }
    setLoading(true);
    setTemplates([]);
    let tipoMensagem = '';
    if (ticket.messages || ticket.messages) {
      if (ticket.messages.length !== 0 || ticket.messages.length !== 0) {
        tipoMensagem = ticket ? (ticket.messages[0].mediaType !== "template" ? (ticket.messages[0].fromMe ? "vendedor" : "usuario") : "template") : ticket.messages[0].mediaType !== "template" ? (ticket.messages[0].fromMe ? "vendedor" : "usuario") : "template";
      }
    }

    if (tipoMensagem === '' && !enviaTemplatePrimeiroContato) {
      toast.error(`Não é possivel enviar template, pois não existe nenhuma conversa neste ticket!`);
      setLoading(false);
    }

    // 5 = primeira interação // 4 - retorno contato
    const MttId = (enviaTemplatePrimeiroContato ? 5 : 4);

    const interactionType = await api.get(`/interactionType/${MttId}`);

    //validar se tem limite de acordo com o tipo para enviar template
    const templateSentCount = await api.get(`/templateMessage/${user.id}/${MttId}`)
    const sentCount = templateSentCount.data?.count;

    const settings = await api.get(`/settings`);
    const templateLimit = parseInt(settings.data.find((e) => e.key === interactionType.data?.settingsKey).value);

    if (templateSentCount.data?.status !== true) {
      toast.error(`Não foi possível buscar template. ${templateSentCount.statusText}`);
      return;
    } else {
      if (sentCount >= templateLimit) {
        toast.error(`Não é possível enviar template de ${interactionType.data?.name} pois atingiu o limite de envio diário (${sentCount}/${templateLimit})`);
        return;
      }
    }

    var result = await request.get(`${process.env.REACT_APP_API_LAMPZAP}/buscarTemplatesMeta?InNome=${process.env.REACT_APP_LOJA}&DtUltimaMensagem=${tipoMensagem == "template" ? (ticket ? ticket.lastMessageDate : ticket.lastMessageDate) : (ticket ? ticket.lastMessageUser : ticket.lastMessageUser)}&TipoMensagem=${tipoMensagem}&MttId=${MttId}`).catch(e => {
      toast.error(`Erro na conexão com a API (Erro ${e.response.status ?? '0x101'})`);
      setLoading(false);
    });

    if (result.status == 200) {
      setLoading(false);
      var dados = result.data;

      if (dados.Habilitado !== undefined && dados.Habilitado) {
        setPrimeiroContato(enviaTemplatePrimeiroContato)
        setTemplateModalOpen(true);
        setTemplates(dados.templates);
      } else {
        if (dados.Habilitado === undefined && dados.templates.length == 0) {
          toast.error(`Nenhum template cadastrado para esta instância!`);
        } else {
          let hoje = new Date(tipoMensagem == "template" ? (ticket ? ticket.lastMessageDate : ticket.lastMessageDate) : (ticket ? ticket.lastMessageUser : ticket.lastMessageUser));
          hoje.setDate(hoje.getDate() + (dados && dados.diasBloqueio ? dados.diasBloqueio : 5));
          toast.error(`Você só pode enviar nova Mensagem de Template para essa conversa a partir do dia ${hoje.getDate() < 10 ? `0${hoje.getDate()}` : hoje.getDate()}/${hoje.getMonth() < 10 ? `0${hoje.getMonth() + 1}` : hoje.getMonth() + 1}/${hoje.getFullYear()} as ${hoje.getHours() < 10 ? `0${hoje.getHours()}` : hoje.getHours()}:${hoje.getMinutes() < 10 ? `0${hoje.getMinutes()}` : hoje.getMinutes()} ou após a interação do cliente`);

        }
      }
    } else {
      toast.error(result.data);
      setLoading(false);
    }

  };

  const handleCloseTemplateModal = () => {
    setTemplateModalOpen(false);
  };

  const handleCloseShareContactModal = () => {
    setShareContactModalOpen(false);
  };

  const handleSearchSubmit = async (search, selectedValue, interactionTypeId) => {
    if (search.template == undefined || search.template.length == 0) return;
    setLoading(true);

    const message = {
      read: 1,
      fromMe: true,
      mediaUrl: "",
      type: "template",
      template: search.template,
      bodySemNome: selectedValue,
      body: `*${user?.name}:* [*TEMPLATE*]\n${selectedValue}`,
      quotedMsg: null,
      interactionTypeId
    };
    try {

      if (primeiroContato) {
        setSpyMode(false);
        handleUpdateTicket("open");
        setDisableTemplate(true);
      }

      await api.post(`/messages/${ticketId}`, message);

      const interactionType = await api.get(`/interactionType/${interactionTypeId}`);

      const templateSentCount = await api.get(`/templateMessage/${user.id}/${interactionTypeId}`)
      const sentCount = templateSentCount.data?.count;

      const settings = await api.get(`/settings`);
      const templateLimit = parseInt(settings.data.find((e) => e.key === interactionType.data?.settingsKey).value);

      toast.success(`O template de ${interactionType.data?.name} foi enviado com sucesso. Restam ${templateLimit - sentCount} de ${templateLimit}.`);
    } catch (err) {
      setDisableTemplate(false);
      toastError(err);
    }

    setInputMessage("");
    setShowEmoji(false);
    setLoading(false);
    setReplyingMessage(null);
  };

  const renderReplyingMessage = (message) => {
    return (
      <div className={classes.replyginMsgWrapper}>
        <div className={classes.replyginMsgContainer}>
          <span
            className={clsx(classes.replyginContactMsgSideColor, {
              [classes.replyginSelfMsgSideColor]: !message.fromMe,
            })}
          ></span>
          <div className={classes.replyginMsgBody}>
            {!message.fromMe && (
              <span className={classes.messageContactName}>
                {message.contact?.name}
              </span>
            )}
            {message.body}
          </div>
        </div>
        <IconButton
          aria-label="showRecorder"
          component="span"
          disabled={loading || spyMode || ticketStatus !== "open"}
          onClick={() => setReplyingMessage(null)}
        >
          <ClearIcon className={classes.sendMessageIcons} />
        </IconButton>
      </div>
    );
  };

  if (medias.length > 0) {
    return (
      <Paper elevation={0} square className={classes.viewMediaInputWrapper}>
        <IconButton
          aria-label="cancel-upload"
          component="span"
          onClick={(e) => setMedias([])}
        >
          <CancelIcon className={classes.sendMessageIcons} />
        </IconButton>

        {loading ? (
          <div>
            <CircularProgress className={classes.circleLoading} />
          </div>
        ) : (
          <span>
            {medias[0]?.name}
            {/* <img src={media.preview} alt=""></img> */}
          </span>
        )}
        <IconButton
          aria-label="send-upload"
          component="span"
          onClick={handleUploadMedia}
          disabled={loading}
        >
          <SendIcon className={classes.sendMessageIcons} />
        </IconButton>
      </Paper>
    );
  } else {
    return (
      <Paper square elevation={0} className={classes.mainWrapper}>
        {replyingMessage && renderReplyingMessage(replyingMessage)}
        <div className={classes.newMessageBox}>
          <Fade in={(!mostrarBlocked && (mostrarTemplate24h && !mostrarIniciarConversa) || mostrarBtnTemplate)}>
            <div className={classes.blockedMessageBox}>
              <Button
                startIcon={<ChatIcon />}
                className={classes.blockedMessageBtn}
                color="primary"
                variant="contained"
                disabled={loading || disableTemplate}
                onClick={() => { openTemplateModal(ticket, enviaTemplatePrimeiroContato) }}
              >
                Enviar template de mensagem
              </Button>
            </div>
          </Fade>
          <Fade in={mostrarIniciarConversa}>
            <div className={classes.blockedMessageBox}>
              <Button
                startIcon={<ChatIcon />}
                className={classes.blockedMessageBtn}
                color="primary"
                variant="contained"
                disabled={loading}
                onClick={() => {
                  setSpyMode(false);
                  handleUpdateTicket("open");
                }}
              >
                Iniciar Conversa
              </Button>
            </div>
          </Fade>
          <Fade in={(Boolean(mostrarBlocked))}>
            <div className={classes.blockedMessageBox}>
            <Box textAlign="center" width="100%">
            <DialogTitle>Esse contato está bloqueado!</DialogTitle>
            </Box>
            </div>
          </Fade>
          <Hidden only={["sm", "xs"]}>
            <IconButton
              aria-label="emojiPicker"
              component="span"
              disabled={loading || recording || spyMode || ticketStatus !== "open"}
              onClick={(e) => setShowEmoji((prevState) => !prevState)}
            >
              <MoodIcon className={classes.sendMessageIcons} />
            </IconButton>
            {showEmoji ? (
              <div className={classes.emojiBox}>
                <ClickAwayListener onClickAway={(e) => setShowEmoji(false)}>
                  <Picker
                    perLine={16}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={handleAddEmoji}
                  />
                </ClickAwayListener>
              </div>
            ) : null}

            <input
              multiple
              type="file"
              accept="image/png, image/jpeg, application/pdf"
              id="upload-button"
              disabled={loading || recording || spyMode || ticketStatus !== "open"}
              className={classes.uploadInput}
              onChange={handleChangeMedias}
            />
            <label htmlFor="upload-button">
              <IconButton
                aria-label="upload"
                component="span"
                disabled={loading || recording || spyMode || ticketStatus !== "open"}
              >
                <AttachFileIcon className={classes.sendMessageIcons} />
              </IconButton>
            </label>

            <div className={classes.shareContainer}>
              <IconButton
                aria-label="share-options-button"
                component="span"
                disabled={loading || recording || spyMode || ticketStatus !== "open"}
                onClick={() => setshowShareOptions((prevState) => !prevState)}
                className={`${classes.rotateIcon} ${showShareOptions ? classes.rotateIconActive : ''}`}
              >
              <AddIcon className={classes.sendMessageIcons} />
              </IconButton>
              {showShareOptions ? (
                <div className={classes.shareBox}>
                  <ClickAwayListener onClickAway={() => setshowShareOptions(false)}>
                    <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                      <li className={classes.shareWrapper} onClick={() => { setShareContactModalOpen(true); }}>
                        <IconButton
                          className={classes.shareIcon}
                          aria-label="share-contact-button"
                          component="span"
                          disabled={loading || recording || spyMode || ticketStatus !== "open"}
                        >
                          <PersonIcon />
                        </IconButton>
                        <Typography style={{ textAlign: "end", margin: "-2.5px" }}>
                          Contato
                        </Typography>
                      </li>
                    </ul>
                  </ClickAwayListener>
                </div>
              ) : null}
            </div>
          </Hidden>
          <Hidden only={["md", "lg", "xl"]}>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleOpenMenuClick}
            >
              <MoreVert></MoreVert>
            </IconButton>
            <Menu
              id="simple-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuItemClick}
            >
              <MenuItem onClick={handleMenuItemClick}>
                <IconButton
                  aria-label="emojiPicker"
                  component="span"
                  disabled={loading || recording || spyMode || ticketStatus !== "open"}
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <MoodIcon className={classes.sendMessageIcons} />
                </IconButton>
              </MenuItem>
              <MenuItem onClick={handleMenuItemClick}>
                <input
                  multiple
                  type="file"
                  id="upload-button"
                  disabled={loading || recording || spyMode || ticketStatus !== "open"}
                  className={classes.uploadInput}
                  onChange={handleChangeMedias}
                />
                <label htmlFor="upload-button">
                  <IconButton
                    aria-label="upload"
                    component="span"
                    disabled={loading || recording || spyMode || ticketStatus !== "open"}
                  >
                    <AttachFileIcon className={classes.sendMessageIcons} />
                  </IconButton>
                </label>
              </MenuItem>
              <MenuItem onClick={handleMenuItemClick}>
                <IconButton
                  aria-label="send-options-button"
                  component="span"
                  disabled={loading || recording || spyMode || ticketStatus !== "open"}
                  onClick={(e) => setShowEmoji((prevState) => !prevState)}
                >
                  <AddIcon className={classes.sendMessageIcons} />
                </IconButton>
              </MenuItem>
            </Menu>
          </Hidden>
          <div className={classes.messageInputWrapper}>
            <InputBase
              inputRef={(input) => {
                input && input.focus();
                input && (inputRef.current = input);
              }}
              className={classes.messageInput}
              placeholder={
                ticketStatus === "open" && spyMode === false
                  ? i18n.t("messagesInput.placeholderOpen")
                  : mostrarBlocked ? 'Esse contato está bloqueado!' : i18n.t("messagesInput.placeholderClosed")
              }
              multiline
              maxRows={5}
              value={inputMessage}
              onChange={handleChangeInput}
              disabled={spyMode || recording || loading || ticketStatus !== "open" || mostrarTemplate24h || mostrarBtnTemplate}
              onPaste={(e) => {
                ticketStatus === "open" && handleInputPaste(e);
              }}
              onKeyPress={(e) => {
                if (loading || e.shiftKey) return;
                else if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
            />
            {typeBar ? (
              <ul className={classes.messageQuickAnswersWrapper}>
                {quickAnswers.map((value, index) => {
                  return (
                    <li
                      className={classes.messageQuickAnswersWrapperItem}
                      key={index}
                    >
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={() => handleQuickAnswersClick(value.message)}>
                        {`${value.shortcut} - ${value.message}`}
                      </a>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div></div>
            )}
          </div>
          {inputMessage ? (
            <IconButton
              aria-label="sendMessage"
              component="span"
              onClick={handleSendMessage}
              disabled={loading}
            >
              <SendIcon className={classes.sendMessageIcons} />
            </IconButton>
          ) : recording ? (
            <div className={classes.recorderWrapper}>
              <IconButton
                aria-label="cancelRecording"
                component="span"
                fontSize="large"
                disabled={loading}
                onClick={handleCancelAudio}
              >
                <HighlightOffIcon className={classes.cancelAudioIcon} />
              </IconButton>
              {loading ? (
                <div>
                  <CircularProgress className={classes.audioLoading} />
                </div>
              ) : (
                <RecordingTimer />
              )}

              <IconButton
                aria-label="sendRecordedAudio"
                component="span"
                onClick={handleUploadAudio}
                disabled={loading}
              >
                <CheckCircleOutlineIcon className={classes.sendAudioIcon} />
              </IconButton>
            </div>
          ) : (
            <IconButton
              aria-label="showRecorder"
              component="span"
              disabled={loading || spyMode || ticketStatus !== "open"}
              onClick={handleStartRecording}
            >
              <MicIcon className={classes.sendMessageIcons} />
            </IconButton>
          )}
        </div>
        <TemplateModal open={templateModalOpen} templates={templates} onClose={handleCloseTemplateModal} handleSearchSubmit={handleSearchSubmit} />
        <ShareContactModal open={shareContactModalOpen} templates={templates} onClose={handleCloseShareContactModal} handleSearchSubmit={handleSearchSubmit} ticketId={ticketId} />
      </Paper>
    );
  }
};

export default MessageInput;
