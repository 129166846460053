import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip
} from "@material-ui/core";

import {
  AddCircleOutline,
  DeleteForever,
  DeleteOutline,
  Edit,
  Search
} from "@material-ui/icons";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ReasonModal from "../../components/ReasonModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_REASONS") {
    const reasons = action.payload;
    const newReasons = [];

    reasons.forEach((reason) => {
      const reasonIndex = state.findIndex((s) => s.id === reason.id);
      if (reasonIndex !== -1) {
        state[reasonIndex] = reason;
      } else {
        newReasons.push(reason);
      }
    });

    return [...state, ...newReasons];
  }

  if (action.type === "UPDATE_REASONS") {
    const reason = action.payload;
    const reasonIndex = state.findIndex((s) => s.id === reason.id);

    if (reasonIndex !== -1) {
      state[reasonIndex] = reason;
      return [...state];
    } else {
      return [reason, ...state];
    }
  }

  if (action.type === "DELETE_REASON") {
    const reasonId = action.payload;

    const reasonIndex = state.findIndex((s) => s.id === reasonId);
    if (reasonIndex !== -1) {
      state.splice(reasonIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Reasons = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedReason, setSelectedReason] = useState(null);
  const [deletingReason, setDeletingReason] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [reasons, dispatch] = useReducer(reducer, []);
  const [reasonModalOpen, setReasonModalOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [enabled, setEnabled] = useState(false);

  const fetchReasons = useCallback(async () => {
    try {
      const { data } = await api.get("/reasons/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_REASONS", payload: data.reasons });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetchReasons();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, fetchReasons]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("reasons", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_REASONS", payload: data.reasons });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_REASONS", payload: +data.reasonId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenReasonModal = () => {
    setSelectedReason(null);
    setReasonModalOpen(true);
  };

  const handleCloseReasonModal = () => {
    setSelectedReason(null);
    setReasonModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleEditReason = (reason) => {
    setSelectedReason(reason);
    setReasonModalOpen(true);
  };

  const handleDeleteReason = async (reasonId) => {
    try {
      await api.delete(`/reasons/${reasonId}`);
      toast.success(i18n.t("reasons.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setSearchParam("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchReasons();
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          `${i18n.t("reasons.confirmationModal.deleteTitle")}`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() =>
          handleDeleteReason(deletingReason.id)
        }
      >
        {
          `${i18n.t("reasons.confirmationModal.deleteMessage")}`
        }
      </ConfirmationModal>
      <ReasonModal
        open={reasonModalOpen}
        onClose={handleCloseReasonModal}
        reload={fetchReasons}
        aria-labelledby="form-dialog-title"
        reasonId={selectedReason && selectedReason.id}
      />
      <MainHeader>
        <Title >{i18n.t("reasons.title")} ({reasons.length})</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title={i18n.t("reasons.buttons.add")}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenReasonModal}
            >
              <AddCircleOutline />
            </Button>
          </Tooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">{i18n.t("reasons.table.name")}</TableCell>
              <TableCell align="center">{i18n.t("reasons.table.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {reasons.map((reason) => (
                <TableRow key={reason.id}>
                  <TableCell align="center">
                    {reason.name}
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditReason(reason)}
                      disabled={ reason.id == 1 || reason.id == 2 || reason.name == 'Contato bloqueado' ? disabled : enabled }
                    >
                      <Edit
                        color={reason.id == 1 || reason.id == 2 || reason.name == 'Contato bloqueado' ? 'primary' : 'secondary'}
                      />
                    </IconButton>

                    <IconButton
                      size="small"
                      disabled={reason.id == 1 || reason.id == 2 || reason.name == 'Contato bloqueado' ? disabled : enabled }
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingReason(reason);
                      }}
                    >
                      <DeleteOutline color={reason.id == 1 || reason.id == 2 || reason.name == 'Contato bloqueado' ? 'primary' : 'secondary'} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Reasons;