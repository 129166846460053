import React, { useState, createContext } from "react";

const SpyModeContext = createContext();

const SpyModeProvider = ({ children }) => {
	const [spyMode, setSpyMode] = useState(true);

	return (
		<SpyModeContext.Provider
			value={{ spyMode, setSpyMode }}
		>
			{children}
		</SpyModeContext.Provider>
	);
};

export { SpyModeContext, SpyModeProvider };
