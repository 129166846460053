import React, { useState, useEffect, useReducer, useCallback } from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@material-ui/core";

import {
  DeleteOutline,
  Edit
} from "@material-ui/icons";

import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import AgendamentoModal from "../../components/AgendamentoModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_AGENDAMENTOS") {
    const agendamentos = action.payload;
    const newAgendamentos = [];

    agendamentos.forEach((agendamento) => {
      const agendamentoIndex = state.findIndex((s) => s.id === agendamento.id);
      if (agendamentoIndex !== -1) {
        state[agendamentoIndex] = agendamento;
      } else {
        newAgendamentos.push(agendamento);
      }
    });

    return [...state, ...newAgendamentos];
  }

  if (action.type === "UPDATE_AGENDAMENTOS") {
    const agendamento = action.payload;
    const agendamentoIndex = state.findIndex((s) => s.id === agendamento.id);

    if (agendamentoIndex !== -1) {
      state[agendamentoIndex] = agendamento;
      return [...state];
    } else {
      return [agendamento, ...state];
    }
  }

  if (action.type === "DELETE_AGENDAMENTO") {
    const ageId = action.payload;

    const agendamentoIndex = state.findIndex((s) => s.id === ageId);
    if (agendamentoIndex !== -1) {
      state.splice(agendamentoIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Agendamentos = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [selectedAgendamento, setSelectedAgendamento] = useState(null);
  const [deletingAgendamento, setDeletingAgendamento] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchDataInicio, setSearchDataInicio] = useState("");
  const [searchDataFim, setSearchDataFim] = useState("");
  const [agendamentos, dispatch] = useReducer(reducer, []);
  const [agendamentoModalOpen, setAgendamentoModalOpen] = useState(false);

  const fetchAgendamentos = useCallback(async () => {
    try {
      const { data } = await api.get("/agendamentos/", {
        params: { searchParam, pageNumber, searchStatus, searchDataInicio, searchDataFim },
      });
      dispatch({ type: "LOAD_AGENDAMENTOS", payload: data.agendamentos });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  }, [searchParam, pageNumber, searchStatus, searchDataInicio, searchDataFim]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam, searchStatus, searchDataInicio, searchDataFim]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
        fetchAgendamentos();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, searchStatus, searchDataInicio, searchDataFim, fetchAgendamentos]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("agendamentos", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_AGENDAMENTOS", payload: data.agendamentos });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_AGENDAMENTOS", payload: +data.agendamentos });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenAgendamentoModal = () => {
    setSelectedAgendamento(null);
    setAgendamentoModalOpen(true);
  };

  const handleCloseAgendamentoModal = () => {
    setSelectedAgendamento(null);
    setAgendamentoModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };
  const handleSearchStatus = (event) => {
    setSearchStatus(event.target.value);
  };
  const handleSearchDataInicio = (event) => {
    setSearchDataInicio(event.target.value);
  };
  const handleSearchDataFim = (event) => {
    setSearchDataFim(event.target.value);
  };
  const handleEditAgendamento= (agendamento) => {
    setSelectedAgendamento(agendamento);
    setAgendamentoModalOpen(true);
  };

  const handleDeleteAgendamento = async (ageId) => {
    try {
      await api.delete(`/agendamentos/${ageId}`);
      toast.success(i18n.t("agendamentos.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingAgendamento(null);
    setSearchParam("");
    setSearchStatus("");
    setPageNumber(1);

    dispatch({ type: "RESET" });
    setPageNumber(1);
    await fetchAgendamentos();
  };

  const handleOpenTicket = async (ticketId) => {
    if (!ticketId) return;
    setLoading(true);
    try {
      history.push(`/tickets/${ticketId}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          `${i18n.t("agendamentos.confirmationModal.deleteTitle")}`
          
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteAgendamento(deletingAgendamento.id)
        }
      >
        {
           `${i18n.t("agendamentos.confirmationModal.deleteMessage")}`

        }
      </ConfirmationModal>
      <AgendamentoModal
        open={agendamentoModalOpen}
        onClose={handleCloseAgendamentoModal}
        reload={fetchAgendamentos}
        aria-labelledby="form-dialog-title"
        ageId={selectedAgendamento && selectedAgendamento.id}
      />
      <MainHeader>
        <Title >{i18n.t("agendamentos.title")} ({agendamentos.length})</Title>
        <MainHeaderButtonsWrapper>
        
        <Paper className={classes.paper}>
            <TextField
              id="ticketId"
              label="Id do Ticket"
              type="text"
              onChange={handleSearch}
              className={classes.textField}
            />
            <TextField
              id="datetime-local"
              label="Dt. Age. Inicio"
              type="datetime-local"
              onChange={handleSearchDataInicio}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label="Dt. Age. Fim"
              type="datetime-local"
              onChange={handleSearchDataFim}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
          />
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="searchStatus"
            label="Status"
						className={classes.settingOption}
						onChange={handleSearchStatus}
					>
						<option value="pending">
						  AGUARDANDO ENVIO
						</option>
						<option value="sended">
						  ENVIADO
						</option>
            <option value="notsended">
						  NÃO ENVIADO
						</option>
					</Select>
				</Paper>

        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">Ticket</TableCell>
              <TableCell align="center">Texto</TableCell>
              <TableCell align="center">Data/Hora</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Função</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {agendamentos.map((agendamento) => (
                <TableRow key={agendamento.id}>
                  <TableCell align="center">
                    {agendamento.ticketId}
                  </TableCell>
                  <TableCell align="center">
                    {agendamento.text}
                  </TableCell>
                  <TableCell align="center">
                    {format((new Date(agendamento.sendDate)), "H:mm dd/MM/yyyy")}
                  </TableCell>
                  <TableCell align="center">
                    {agendamento.status === "pending" ? 'AGUARDANDO ENVIO' : (agendamento.status === "sended" ? "ENVIADO" : (agendamento.status === "notsended" ? "NÃO ENVIADO" : ""))}
                  </TableCell>
                  <TableCell align="center">
                     <IconButton
                      size="small"
                      onClick={() => handleOpenTicket(agendamento.ticketId)}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => handleEditAgendamento(agendamento)}
                      >
                        <Edit color="secondary"/>
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeletingAgendamento(agendamento);
                      }}
                    >
                      <DeleteOutline color="secondary"/>
                    </IconButton>

                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Agendamentos;